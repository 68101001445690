.approved-sections {
  max-height: 66vh;
  overflow-y: scroll;
}

#approved-div::-webkit-scrollbar {
  visibility: hidden;
}

#approved-div::-webkit-scrollbar-thumb {
  visibility: hidden;
}

#approved-div:hover::-webkit-scrollbar {
  visibility: visible;
}

#approved-div:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.documents-head-sections {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 45px;
}

.document-head {
  font-size: 1.375rem;
  color: var(--color-blue2);
  /* padding-bottom: 2rem; */
}
.green-btn {
  border: 1px solid var(--color-green1);
  color: var(--color-green1);
}
.document-head p {
  font-family: "siena-bold";
  font-size: 1.7rem;
  color: var(--color-blue2);
  margin-bottom: 32px;
}

.document-filter {
  /* max-width: 400px; */
  width: 50%;
  background-color: var(--color-white);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-bottom: 5px solid var(--color-blue2);
}

.document-filter .text-head {
  padding: 0.8rem 1rem;
  z-index: 1;
}

.document-filter .text-head .text-sec:visited {
  color: var(--color-blue2);
}

.document-filter .text-head .text-sec {
  font-size: 1.5rem;
  color: var(--color-gray4);
  cursor: pointer;
  margin: 0;
  transition: 0.4s;
  text-transform: uppercase;
  font-family: "siena-regular";
}

.circle-pending {
  display: flex;
  justify-content: left;
}

.circle-disapproved {
  display: flex;
  justify-content: right;
}

.circle-approved {
  display: flex;
  justify-content: center;
}

.circle-document img {
  margin: -5px;
}

.document-search {
  padding: 1rem;
  width: 30%;
  display: flex;
  align-items: center;
  background: var(--color-white);
  border-radius: 10px;
}

.input-document-search {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-gray5);
  padding: 5px;
  font-family: "siena-light";
  font-size: 0.875rem;
}

.document-search img {
  padding-left: 0.9rem;
  width: 2rem;
}

.document-active {
  color: var(--color-blue2) !important;
}

.document-inactive {
  color: var(--color-gray4);
}

/* --------pending----------- */
.pending-section {
  margin: 1rem 0rem;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
}

.pending-color {
  color: var(--color-gray4);
  font-size: 1.35rem;
  margin-right: 5px;
}

.pending-color > span {
  color: var(--color-blue2);
  margin: 0;
  font-weight: none;
  font-size: 1.25rem;
}

.first-p-of-payment {
  padding-bottom: 0.5rem;
}

.pending-date {
  font-size: 1.125rem;
  color: var(--color-gray7);
  margin-bottom: 0;
}

.image-section {
  padding: 0;
  display: flex;
  justify-content: center;
}

.image-section img {
  width: 100%;
  margin-right: 0.5rem;
}

.pending-buttn-section {
  padding: 1rem 0rem 1rem 0rem;
  display: flex;
  justify-content: flex-start;
}

/* --------Aproved----------- */
.approved-section {
  margin: 1rem 0rem;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 1rem;
}

.approved-color {
  color: var(--color-gray4);
  font-size: 1.35rem;
  margin: 0;
}

.approved-color > span {
  color: var(--color-blue2);
  margin: 0;
  font-weight: none;
  font-size: 1.25rem;
}

.approved-date {
  font-size: 1.125rem;
  color: var(--color-gray7);
  margin-bottom: 0;
}

.image-section {
  padding: 0;
  display: flex;
  justify-content: center;
}

.image-section img {
  width: 100%;
}

.approved-list {
  list-style: disc !important;
}

/* --------Disapproved----------- */
.disapproved-section {
  margin: 1rem 0rem;
  background-color: var(--color-white);
  border-radius: 0.5rem;
  padding: 1rem;
}

.disapproved-color {
  color: var(--color-gray4);
  font-size: 1.25rem;
  margin: 0;
}

.disapproved-color > span {
  color: var(--color-blue2);
  margin: 0;
  font-weight: none;
  font-size: 1.25rem;
}

.disapproved-date {
  font-size: 1.125rem;
  color: var(--color-gray7);
  margin-bottom: 0;
}

.image-section {
  padding: 0;
  display: flex;
  justify-content: center;
}

.image-section img {
  width: 3rem;
}

.disapproved-list {
  list-style: disc !important;
}

/* .disapproved-mid {
  padding: 0px 20px 20px 20px;
} */

.disapproved-motivo {
  color: var(--color-gray4);
  padding-bottom: 5px;
  font-size: 1.35rem;
  margin: 0;
}

.disapproved-para {
  color: var(--color-gray7);
  font-size: 1.125rem;
  margin: 0;
}

.copyIcons {
  margin-left: 0.5rem;
  color: var(--color-blue2);
}

.documentTrackingpage {
  justify-content: center;
  align-items: center;
  display: flex;
  height: calc(100% - 7rem);
}

.tracking-filter {
  background-color: var(--color-white);
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-bottom: 5px solid var(--color-blue2);
}
.tracking-filter .text-head {
  padding: 0.8rem 1rem;
  z-index: 1;
}

.tracking-filter .text-head .text-sec:visited {
  color: var(--color-blue2);
}

.tracking-filter .text-head .text-sec {
  font-size: 1.5rem;
  color: var(--color-gray4);
  cursor: pointer;
  margin: 0;
  transition: 0.4s;
  text-transform: uppercase;
  font-family: "siena-regular";
}
.documents-head-sections-tracking {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 174px;
}
@media only screen and (min-width: 150px) and (max-width: 1200px) {
  .document-filter {
    /* max-width: 400px; */
    width: 100%;
  }
  .document-search {
    width: 100%;
    margin: 1rem 0;
  }
}
@media only screen and (min-width: 150px) and (max-width: 768px) {
  .pending-color {
    font-size: 1.5rem;
  }
  .pending-date {
    font-size: 1.5rem;
  }
  .btn-approval {
    margin-top: 1rem;
  }
  .approved-color {
    font-size: 1.5rem;
  }
  .approved-date {
    font-size: 1.5rem;
  }
  .disapproved-color {
    font-size: 1.5rem;
  }
  .disapproved-date {
    font-size: 1.5rem;
  }
}
