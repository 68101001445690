
.section-login {
  width: 100%;
  height: 100%;
  background-image: url("/public/icon/banner.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.btn-login {
  height: 40px;
  padding: 0px 32px;
  background-color: var(--color-blue1);
  border-radius: 80px;
  border: none;
  color: var(--color-white);
  font-family: "siena-semibold";
  font-size: 1rem;
}

/* .input-defalut-login {
  margin: 30px 0px 0px 0px;
  position: relative;
} */

.min-width-reset {
  min-width: 16rem;
}

.input-defalut-login > div.MuiFormControl-root {
  width: 100%;
  padding-bottom: 1rem;
}

.span-error {
  opacity: 1;
  color: var(--color-red0);
  font-family: "siena-light";
  font-size: 1rem;
}

.error {
  border-bottom: 1px solid var(--color-red0) !important;
}

.active-error {
  opacity: 1;
}

@media (max-width: 425px) {
  /* .input-defalut-login {
    margin: 25px 0px 0px 0px;
  } */
}

@media (max-width: 1024px) {
  /* .section-login {
    background-size: 190% 120%;
    background-position: 50% 25%;
  } */
}

@media (max-width: 425px) {
  /* .section-login {
    background-position: 50% 80%;
  } */
}

.login-main {
  /* padding-top: 10rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-login {
  padding: 20px 0px;
}

.container-logo {
  text-align: center;
  /* padding-bottom: 6rem; */
}

.container-form {
  padding: 3rem;
  width: 31rem;
  background-color: var(--color-white);
  border-radius: 20px;
  margin: 69px auto;
}

.title {
  font-family: "siena-medium";
  color: var(--color-blue0);
  font-size: 2rem;
  text-align: start;
}

.form-login {
  padding: 25px;
}

.input-checkbox {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.input-checkbox input {
  width: 14px;
  height: 14px;
}

.input-checkbox label {
  font-family: "siena-light";
  font-size: 0.875rem;
  margin-left: 6px;
  color: var(--color-gray1);
}

.submit {
  margin-top: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.submit a {
  font-family: "siena-light";
  color: var(--color-blue1);
  font-size: 1rem;
}

@media only screen and (max-width: 567px) {
  .login-main {
    width: 100%;
  }
  .container-form {
    width: 100%;
  }

  .container-logo img {
    width: 177px;
    height: auto;
  }
}

/* @media (max-width: 425px) {
  .container-form {
    margin: 69px auto;
  }
  .title {
    font-size: 1.5rem;
  }
  .container-logo {
    text-align: center;
  }
  .container-logo img {
    width: 177px;
    height: auto;
  }
} */
