.btn-out-section {
  display: block;
  padding: 5px 20px;
  color: var(--color-blue1);
  font-size: var(--fs-small-lg);
  border: 1px solid var(--color-blue1);
  background-color: transparent;
  border-radius: 50px;
}
.btn-out-section-green {
  display: block;
  padding: 0.5px;
  width: 95px;
  color: #1dad39;
  font-size: var(--fs-small-lg);
  border: 1px solid #1dad39;
  background-color: white;
  border-radius: 50px;
  font-size: 14px;
  font-family: "siena-light";
}
.btn-filled-section {
  display: block;
  padding: 5px 20px;
  color: var(--color-white);
  font-size: var(--fs-small-lg);
  border: none;
  background-color: var(--color-blue1);
  border-radius: 50px;
}
.btnn-section {
  color: var(--color-blue1);
  font-size: var(--fs-subtitle);
  border: none;

  background-color: none;
}
.btnn-icon {
  display: inline-flex;
  color: var(--color-white);
  border: none;
  background-color: var(--color-blue1);
  border-radius: 50px;
  align-items: center;
  font-size: 1.3rem;
}

.btnn-icon-div {
  display: inline-flex;
  padding: 0px 15px;
  color: var(--color-white);
  border: none;
  background-color: var(--color-blue1);
  border-radius: 50px;
  align-items: center;
  height: 32px;
}

.btn-out-section-red {
  display: block;
  padding: 0.5px;
  width: 95px;
  color: #c40606a0;
  /* font-size: var(--fs-small-lg); */
  border: 1px solid #c40606a0;
  background-color: white;
  border-radius: 12px;
  text-align: center;
  font-size: 14px;
  font-family: "siena-light";
}
