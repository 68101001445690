.input-search {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-gray5);
  padding: 1px 0px;
  font-family: "siena-light";
  /* font-size: var(--fs-md); */
  font-size: 1.1rem;
}

.input-search:focus,
.select .title-select:focus {
  outline: none;
  border-bottom: 1px solid var(--color-blue2);
}

.select {
  min-width: 65px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  width: 27%;
  padding-right: 0.03rem;
  padding-top: 0.3rem;
}

.search-div {
  width: 60%;
  display: flex;
  align-items: flex-end;
  padding: 0 0 1px 10px;
}

.select .title-select {
  width: 100%;
  font-family: "siena-light";
  font-size: var(--fs-md);
  font-size: 1.1rem;
  padding: 5px 0px;
  color: var(--color-gray4);
  position: relative;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid var(--color-gray5);
}

.section-first {
  padding-inline: 0;
}
.section-first > p {
  font-size: var(--fs-mid);
  color: var(--color-blue2);
  padding-bottom: 28px;
  margin: 0;
  font-family: "siena-bold";
}
.section-first-header {
  width: 436px;
  background-color: var(--color-white);
  border-radius: 10px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.menu-section {
  background-color: var(--color-white);
  /* padding: 0.5rem; */
  border-radius: 0.8rem;
  justify-content: space-between;
  padding: 8px 12px;
  height: 100%;
}

.btn-section button {
  border-radius: 1.5rem;
  background-color: var(--color-blue1);
  border: none;
  color: var(--color-white);
  padding: 0.5rem 1rem;
}
.section-second {
  background-color: var(--color-white);
  margin-top: 10px;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 5px;
}

.color-section {
  color: var(--color-gray4);
  /* font-size: var(--fs-value); */
  font-size: 1.5rem;
  font-family: "siena-bold";
}

.color-section + p {
  /* font-size: var(--fs-value); */
  color: var(--color-blue2);
  font-size: 1.5rem;
  font-family: "siena-bold";
}
.color-section-other {
  color: var(--color-gray4);
  /* font-size: var(--fs-value); */
  font-size: 1.5rem;

  font-family: "siena-regular";
}
.color-section-other + p {
  color: var(--color-gray7);
  font-size: 1.5rem;
  font-family: "siena-medium";
}
.teams-row div.col-6 {
  padding: 0;
}
.buttn-column {
  align-items: center;
}

.new-user-button {
  width: auto;
  padding: 0;
}

.button-section {
  padding-top: 0.8rem;
  text-align: end;
}

.button-section .edit-btn {
  color: var(--color-blue1);
  font-size: 1.35rem;
  font-family: "siena-regular";
  border: none;
  background-color: transparent;
  padding: 10px 0px;
}
.button-section .delete-btn {
  font-size: 1.35rem;
  font-family: "siena-regular";
  border: none;
  background-color: transparent;
  padding: 10px 0px;
  color: var(--color-gray4);
}
.button-section img {
  padding: 0 0 0.5rem 0.3rem;
}

.my-team-main {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 50px;
}

/* .my-team-first {
  height: 8rem;
} */

.my-team-second {
  height: 5rem;
  padding: 1rem 0;
}

.user-div {
  height: calc(100% - 8rem);
  overflow-y: auto;
}

/* New User CSS */
.new-user {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 50px;
}
.new-user-text {
  color: var(--color-blue2);
  font-family: "siena-bold";
  font-size: 1.8rem;
  padding-bottom: 28px;
}

.btn-add-user {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-add-user svg.MuiSvgIcon-root {
  height: 2.5rem;
  width: 3rem;
  color: var(--color-blue2);
}

.add-user-div {
  background: var(--color-white);
  border-radius: 5px;
  margin: 6px 0px;
  padding: 36px 20px 28px 20px;
}
.add-user-textfield .MuiFormLabel-root {
  font-size: 1.5rem;
  font-family: "siena-light";
  color: var(--color-gray1);
}
.add-user-textfield
  div.MuiFormControl-root.MuiTextField-root
  div.MuiInputBase-root.MuiInput-root.MuiInput-underline {
  padding-bottom: 8px;
}
.user-permission-head {
  padding-left: 0.5rem;
  font-size: 1.5rem;
}
.add-user-text {
  flex-wrap: wrap;
  width: 50%;
  padding-right: 1rem;
}
.add-user-textfield {
  min-width: 65px;
  transition: 0.3s;
  padding-right: 3%;
  width: 48%;
  width: 48%;
}

.add-user-textfield div.MuiFormControl-root {
  width: 100%;
}

.user-permissions-div {
  background-color: var(--color-white);
  padding: 37px 39px;
  border-radius: 5px;
}
.user-permissions-div > div {
  color: var(--color-gray4);
  font-size: var(--fs-label);
  font-family: "siena-bold";
}

.user-permissions-options {
  display: flex;
  flex-wrap: wrap;
}

.user-permissions-options div {
  display: flex;
  width: 10rem;
  margin: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.user-permissions-options p {
  padding-left: 1rem;
  color: var(--color-blue2);
  font-size: var(--fs-value);
  font-family: "siena-bold";
  margin-bottom: 0;
}

.save-button {
  margin-top: 1rem;
}

/* Model Delete */
.delete-model {
  display: flex;
  flex-direction: column;
}

.delete-model h2,
.delete-model p,
.delete-model div {
  text-align: center;
}
.delete-model p,
.delete-model div {
  margin-top: 1rem;
}

.delete-model h2 {
  font-size: var(--fs-one);
  color: var(--color-blue2);
}

.delete-modal-main {
  width: 100%;
  padding: 1rem;
}

.btn-delete-modal {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.btn-delete-modal button {
  border-radius: 10rem;
  background-color: var(--color-white);
  width: 6rem;
}

.btn-delete-modal button:first-child {
  color: var(--color-blue1);
}

.btn-delete-modal button:first-child:hover {
  color: var(--color-white);
}
.btn-delete-modal button:last-child {
  color: #bb2d3b;
}
.btn-delete-modal button:last-child:hover {
  color: var(--color-white);
}
.show-more-teams {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}
.btn-newuser {
  font-size: 1.2rem;

  font-family: "siena-semibold";

  color: var(--color-blue1);
  padding: 12px 34px;
  border-radius: 80px;
  border: 1px solid var(--color-blue1);
  cursor: pointer;
  margin-top: 32px;
}

/* -------------------Media Query---------------------- */

@media only screen and (min-width: 261px) and (max-width: 991px) {
  .new-user {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .report-sec-second {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 479px) and (max-width: 768px) {
  .buttn-column.order-2 {
    margin-bottom: 1rem;
  }
  .my-team-first,
  .my-team-second {
    margin-bottom: 1.5rem;
  }
  .my-team-main {
    height: calc(100% - 1rem);
  }
}
@media screen and (max-width: 479px) {
  .my-team-first,
  .my-team-second {
    margin-bottom: 4.5rem;
  }
  .buttn-column.order-2 {
    margin-bottom: 1rem;
  }
  .btn-icon > .css-i4bv87-MuiSvgIcon-root {
    font-size: var(--fs-title) !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 465px) {
  .my-team-main {
    height: calc(100% - 4rem);
  }
  .color-section + p {
    font-size: var(--fs-subheading);
  }
}
@media screen and (min-width: 466px) and (max-width: 776px) {
  .my-team-main {
    height: calc(100% - 1rem);
  }
}
@media screen and (max-width: 768px) {
  .section-first-header {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .button-section {
    text-align: inherit;
  }
}
@media only screen and (min-width: 576px) and (max-width: 991px) {
  .button-section {
    display: flex;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1399px) {
}
@media only screen and (min-width: 1400px) {
}
