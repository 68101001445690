@font-face {
  font-family: "siena-light";
  src: url("./fonts/FSSienaWeb-Light.woff") format("woff"),
    url("./fonts/FSSienaWeb-Light.woff2") format("woff2");
}

@font-face {
  font-family: "siena-medium";
  src: url("./fonts/FSSienaWeb-Medium.woff") format("woff"),
    url("./fonts/FSSienaWeb-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "siena-semibold";
  src: url("./fonts/FSSienaWeb-SemiBold.woff") format("woff"),
    url("./fonts/FSSienaWeb-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "siena-bold";
  src: url("./fonts/FSSienaWeb-Bold.woff") format("woff"),
    url("./fonts/FSSienaWeb-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "siena-regular";
  src: url("./fonts/FSSienaWeb-Regular.woff") format("woff"),
    url("./fonts/FSSienaWeb-Regular.woff2") format("woff2");
}

:root {
  /* --Font-color-- */
  --color-white: #ffffff;
  --color-white1: #ededed;
  --color-white2: #fafafa;
  --color-blue0: #13095e;
  --color-blue1: #006bff;
  --color-blue2: #120b5e;
  --color-blue3: #799ccc;
  --color-blue4: #91bfff;
  --color-gray0: #bababa;
  --color-lightgray: #f6f6f6;
  --color-gray1: #666666;
  --color-gray2: #4e4e4e;
  --color-gray3: #797979;
  --color-gray4: #959595;
  --color-gray5: #d6d6d6;
  --color-hovercolor: #f6f6f630;
  --color-gray6: #ffffff86;
  --color-gray7: #404040;
  --color-red0: #c40606;
  --color-red1: #c40606a0;
  --color-purpe0: #542d89;
  --color-green0: #bcd632;
  --color-green1: #1dad39;
  --color-green2: #1dad39aa;
  --color-orange0: #ff9400;

  /* font family */

  --family-bold: "siena-bold";
  --family-medium: "siena-medium";
  --family-light: "siena-light";

  /* --font-size--*/

  --fs-small: 0.625rem;
  --fs-small-md: 0.875rem;
  --fs-small-lg: 0.675rem;
  --fs-mid-sm: 0.75rem;
  --fs-subtitle: 1rem;
  --fs-one: 1.5rem;
  --fs-mid: 1.875rem;
  --fs-md: 1.375rem;
  --fs-subheading: 1.438rem;
  --fs-label: 1.125rem;
  --fs-value: 1.25rem;
  --fs-title: 2rem;
  --fs-lg: 2.875rem;
  --fs-heading: 4rem;
  --fs-four: 4.438rem;
  --fs-seven: 1.7rem;
  /* ----Line height---- */
  --fs-lineheight: 4rem;
}

/*  */
html,
body,
#root {
  height: 100%;
  font-family: "siena-bold", "siena-medium";
}

#root > div:nth-child(2) {
  height: 100%;
  background: var(--color-white1);
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: none;
}

button {
  cursor: pointer;
}

/* button:active {
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
} */

::-webkit-scrollbar {
  background-color: var(--color-gray5);
  width: 6px;
}
::-webkit-scrollbar:horizontal {
  background-color: var(--color-gray5);
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-gray0);
}

.container-fluid {
  margin: 0;
  padding: 0;
}

.row {
  margin: 0;
  padding: 0;
}

.col-3,
.col-4 {
  margin: 0;
  padding: 0;
}

.col-9,
.col-8,
.col-4 {
  margin: 0;
  padding: 0;
}

.pointer {
  cursor: pointer;
}

#root {
  background-color: var(--color-white1);
}

@media screen and (max-width: 479px) {
  body {
    width: 100%;
    height: 100%;
  }

  html {
    font-size: 7px;
  }

  :root {
    --fs-small: 1.5rem;
    --fs-small-md: 2rem;
    --fs-small-lg: 1.5rem;
    --fs-mid-sm: 1.5rem;
    --fs-subtitle: 2rem;
    --fs-one: 1.5rem;
    --fs-mid: 2.875rem;
    --fs-md: 2.875rem;
    --fs-subheading: 1.438rem;
    --fs-label: 2rem;
    --fs-value: 2rem;
    --fs-title: 2rem;
    --fs-lg: 2.875rem;
    --fs-heading: 4rem;
    --fs-four: 4.438rem;
    --fs-lineheight: 4rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  html {
    font-size: 9px;
  }

  :root {
    --fs-small: 1rem;
    --fs-small-md: 1.5rem;
    --fs-small-lg: 1.125rem;
    --fs-mid-sm: 1rem;
    --fs-subtitle: 1rem;
    --fs-one: 1.5rem;
    --fs-mid: 1.875rem;
    --fs-md: 1.375rem;
    --fs-subheading: 2rem;
    --fs-label: 1.5rem;
    --fs-value: 1.25rem;
    --fs-title: 2rem;
    --fs-lg: 2.875rem;
    --fs-heading: 4rem;
    --fs-four: 4.438rem;
    --fs-lineheight: 4rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  html {
    font-size: 10px;
  }

  :root {
    --fs-small: 1.25rem;
    --fs-small-md: 1.5rem;
    --fs-small-lg: 1rem;
    --fs-mid-sm: 0.75rem;
    --fs-subtitle: 1.5rem;
    --fs-one: 1.5rem;
    --fs-mid: 1.875rem;
    --fs-md: 1.875rem;
    --fs-subheading: 1.438rem;
    --fs-label: 1.5rem;
    --fs-value: 1.5rem;
    --fs-title: 2rem;
    --fs-lg: 2.875rem;
    --fs-heading: 4rem;
    --fs-four: 4.438rem;
    --fs-lineheight: 4rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
  html {
    font-size: 11px;
  }

  :root {
    --fs-small: 0.875rem;
    --fs-small-md: 1rem;
    --fs-small-lg: 0.875rem;
    --fs-mid-sm: 0.75rem;
    --fs-subtitle: 1rem;
    --fs-one: 1.5rem;
    --fs-mid: 1.875rem;
    --fs-md: 1.375rem;
    --fs-subheading: 1.438rem;
    --fs-label: 1.25rem;
    --fs-value: 1.25rem;
    --fs-title: 2rem;
    --fs-lg: 2rem;
    --fs-heading: 4rem;
    --fs-four: 4.438rem;
    --fs-lineheight: 4rem;
  }
}

@media only screen and (min-width: 1400px) {
  html {
    font-size: 13px;
  }

  :root {
    --fs-small: 0.625rem;
    --fs-small-md: 0.875rem;
    --fs-small-lg: 0.675rem;
    --fs-mid-sm: 0.75rem;
    --fs-subtitle: 1rem;
    --fs-one: 1.5rem;
    --fs-mid: 1.875rem;
    --fs-md: 1.375rem;
    --fs-subheading: 1.438rem;
    --fs-label: 1.25rem;
    --fs-value: 1.25rem;
    --fs-title: 2rem;
    --fs-lg: 2.875rem;
    --fs-heading: 4rem;
    --fs-four: 4.438rem;
    --fs-lineheight: 4rem;
  }
}
