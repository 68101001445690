.financial-section {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  margin: 0 auto;

  padding-top: 40px;
  margin-bottom: 50px;
}
.date-picker-section .react-calendar-datetime-picker .header {
  background-color: var(--color-blue0) !important;
}
.react-calendar-datetime-picker .daysList .is-disabled {
  background-color: #fff !important;
  color: #fff !important;
}

.date-picker-section .react-calendar-datetime-picker .daysList .is-today {
  box-shadow: inset 0 0 0 2px var(--color-blue0) !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .daysList
  .is-selected-day-range {
  background-color: antiquewhite !important;
  color: black !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .daysList
  .is-selected-day-to {
  background-color: var(--color-blue0) !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .monthList_month.is-selected-month {
  color: var(--color-blue0) !important;
}
.date-picker-section .react-calendar-datetime-picker .is-selectedYearRef {
  color: var(--color-blue0) !important;
}
.date-picker-section
  .react-calendar-datetime-picker
  .daysList
  .is-selected-day-from {
  background-color: var(--color-blue0) !important;
}
.date-picker-section .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #fff !important;
}
.check-date-div .css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 0px !important;
  margin: 0px !important;
}
.container-first > p {
  /* font-size: var(--fs-subheading); */
  font-size: 2rem;

  color: var(--color-blue2);
  margin: 0;
  font-family: var(--family-bold);
  margin-bottom: 32px;
}
.search-div-finance {
  padding: 9px 0 0px 10px;
  width: 60%;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-right: 5px;
}

.mid-section {
  background-color: var(--color-white);
  border-radius: 0.8rem 0.8rem 0rem 0rem;
  border-bottom: 8px solid var(--color-blue2);
  width: 30%;
  padding: 0.5rem;
  font-size: var(--fs-value);
  color: var(--color-blue2);
  text-align: center;
  margin: 0;
  font-family: var(--family-bold);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mid-section > p {
  margin: 0;
  width: 100%;
  text-align: center;
  font-family: "siena-medium";
  font-size: 1.45rem;
  cursor: pointer;
  color: var(--color-blue2);
  text-transform: uppercase;
}

.due-section {
  margin: 1rem 0rem;
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 1.5rem 1.5rem 1.5rem 1.25rem;
}

.due-color {
  margin: 0;
  color: #4e4e4e;
  font-size: var(--fs-md);
}

.text-color1 {
  color: var(--color-blue2);
  font-size: var(--fs-title);
  margin: 0;
  font-family: var(--family-bold);
}

.text-color2 {
  color: #c40606;
  font-size: var(--fs-title);
  margin: 0;
  font-family: var(--family-bold);
}

.text-color2 > span img {
  padding: 0 0 0.5rem 0.3rem;
}

.btns-section {
  text-align: end;
}

.btns-section button {
  border: none;
  background-color: var(--color-blue1);
  border-radius: 5rem;

  font-size: 0.75rem;
  color: var(--color-white);
  padding: 0.4rem 1.5rem;
}

.payment-section {
  margin: 1rem 0rem;
  background-color: var(--color-white);
  border-radius: 10px;
  padding: 1.5rem 1.5rem 1.5rem 1.25rem;
}

.payment-color {
  color: #959595;
  /* font-size: var(--fs-value); */
  margin: 0;
  font-family: var(--family-bold);
  font-size: var(--fs-md);
}

.payment-color > span {
  color: var(--color-blue2);
  margin: 0;
  font-weight: none;
}

.payment-color + p {
  font-family: var(--family-bold);
  font-size: var(--fs-label);
  margin: 0;
  color: var(--color-blue2);
}

.buttn-section {
  padding: 1rem 0rem 1rem 0rem;
  display: flex;
  justify-content: space-evenly;
}

.buttn-section button {
  color: var(--color-blue1);
  padding: 0.2rem 1rem;
  border-radius: 5rem;
  border: 1px solid var(--color-blue1);
  background: none;
}

.payment-top-div {
  display: flex;

  align-items: center;
  justify-content: space-between;
}

.card-head-finance {
  padding: 0 1.5rem;
  border-radius: 0.2rem;
  background-color: var(--color-white);
  width: 75%;
  border-radius: 10px;
}
.card-head-finance .search-filter {
  padding: 0.5rem 0;
}
.payment-top-left {
  justify-content: flex-end;
}

.group-payment-top {
  display: flex;
  justify-content: space-between;
  /* padding-right: 18rem; */
}
.group-payment-top > div {
  min-width: 50%;
}
.group-payment-below {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.alert-icon {
  padding-left: 1rem;
}

.po-number-text {
  display: flex;
}

.payment-section .conatiner .row .col-1 {
  display: flex;
  align-items: center;
}

.payment-section .conatiner .row {
  padding-bottom: 1rem;
}

.border-div {
  border-bottom: 1px solid grey;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

.payment-method {
  display: flex;
}

.payment-method div.JoyBox-root {
  display: flex;
  align-items: center;
}

.payment-method p {
  padding-left: 0.3rem;
}

.payment-conainer {
  background: #fff;
  padding: 2rem 1rem;
  margin-top: 1rem;
  border-radius: 0.2rem;
}

.po-number-text {
  display: flex;
  align-items: center;
}

.show-more-finnance {
  display: flex;
  justify-content: center;
  margin: 0.5rem 0;
}

.btn-outstanding {
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}

.docupload-btn {
  background-color: var(--color-blue1);
  color: var(--color-white);
  font-weight: 500;
  border-radius: 4px;
  padding: 6px 16px;
  font-size: var(--fs-small-md);
  border: none;
  font-family: var(--family-bold);
}

.card-title {
  font-size: var(--fs-title);
}

.payment-color.first-p-of-payment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-color.first-p-of-payment > span {
  display: inline-block;
  width: 229px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 1rem;
}

.ellipsisTable {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.document_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 12rem;
}
@media screen and (max-width: 479px) {
  .group-payment-top + div.col-12 {
    justify-content: normal;
  }

  .payment-section-btn {
    padding-top: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .card-head-finance,
  .mid-section {
    width: 100%;
  }
  .payment-top-left {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .group-payment-top + div.col-12 {
    justify-content: normal;
  }

  .payment-section-btn {
    padding-top: 2rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .payment-top-left {
    justify-content: normal;
    /* margin-top: 1rem; */
  }
  .search-filter {
    padding: 0px 0 6px 10px;
  }

  .card-head-finance {
    width: 100%;
  }

  .mid-section {
    width: 100%;
  }

  .group-payment-top {
    padding-right: 0;
  }

  .payment-section-btn {
    padding-top: 2rem;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1399px) {
  .document_button {
    justify-content: end !important;
    min-width: none !important;
    width: 100%;
  }
}
@media only screen and (min-width: 1162px) and (max-width: 1281px) {
  .document_button {
    justify-content: end !important;
    min-width: 12rem !important;
    width: 100%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1558px) {
  .document_button {
    justify-content: end !important;
    min-width: 12rem !important;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .group-payment-top {
    padding-right: 0;
  }
}

@media only screen and (min-width: 1400px) {
}
