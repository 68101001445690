.mid-container {
  padding: 0;
  max-height: 80vh;
  overflow-y: scroll;
  padding-right: 0.5rem;
}
.left-row-process {
  max-height: 80vh;
  overflow-y: scroll;
  padding-right: 5px;
}

#left-process-div::-webkit-scrollbar {
  visibility: hidden;
}

#left-process-div::-webkit-scrollbar-thumb {
  visibility: hidden;
}

#left-process-div:hover::-webkit-scrollbar {
  visibility: visible;
}

#left-process-div:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.card-head {
  padding: 0.5rem;
  border-radius: 5px;
  background-color: var(--color-white);
  flex-direction: column;
}
.carbon-head{
  margin: 0;
  display: flex;
  justify-content: end;
  align-items: end;
}
.carbon-head span{
  color: rgb(29,173,57);
  font-size: 14px;
}
.carbon-head img{
margin-bottom: 3px;
padding-left: 5px;
}
@media (max-width: 710px) {
  .card-head-head {
    padding: 0.5rem;
    border-radius: 5px;
    grid-column: 1/12;
    grid-row: 1/7;
    padding: 12px 12px;
  }
}
@media (max-width: 710px) {
  .card-head {
    grid-column: 0;
  }
}
.process-details {
  grid-column: "2";
  border: 1px;
}
@media (max-width: 710px) {
  .process-details {
    display: none;
  }
}

.input-search {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-gray5);
  padding: 5px 0px;
  font-family: "siena-light";
  font-size: var(--fs-small-md);
}

.input-search:focus,
.select .title-select:focus {
  outline: none;
  border-bottom: 1px solid var(--color-blue2);
}

.select {
  min-width: 65px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  width: 27%;
}

.select .title-select {
  width: 100%;
  font-family: "siena-light";
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 5px;
  color: var(--color-gray4);
  position: relative;
  cursor: pointer;
  border: none;
  border-bottom: 1px solid var(--color-gray5);
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='--color-blue0' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border-radius: 2px;
}

.search-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.search-div {
  width: 60%;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-right: 5px;
}

.filter-icon {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 7%;
  padding-top: 7px;
  cursor: pointer;
}

/* ---------- */
.card-mid {
  background-color: var(--color-white);
  margin: 1rem 0rem;
  border-radius: 5px;
  display: flex;
  width: 100%;
  height: 4.12rem;
  padding: 0.5rem;
  justify-content: space-between;
  cursor: pointer;
}

.card-mid-selected {
  background-color: var(--color-lightgray);
  margin: 1rem 0rem;
  border-radius: 5px;
  display: flex;
  width: 100%;
  height: 4.12rem;
  padding: 0.5rem;
  justify-content: space-between;
  cursor: pointer;
}

.card-mid:hover {
  background-color: var(--color-lightgray);
}

.card-mid-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0.8rem;
}

.card-mid-img img {
  height: 3rem;
  width: 4rem;
}

.line {
  height: 86%;
  width: 0.01rem;
  background-color: var(--color-gray5);
}
#radiodiv {
  accent-color: #120b5e;
}
.card-mid-head {
  font-size: 1.4rem;
  color: var(--color-gray4);
  font-family: var(--family-bold);
  margin-bottom: 0;
  min-width: 8rem;
}

.order-number-cont {
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-left: 2%;
  max-width: 220px;
}
@media (max-width: 710px) {
  .order-number-cont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .card-mid-para {
    max-width: 10.5rem;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1520px) {
  .order-number-cont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;
  }
  .card-mid-para {
    max-width: 10.5rem;
    margin-bottom: 0.2rem;
  }
}
.colorPreload {
  color: var(--color-purpe0);
}

.colorLoaded {
  color: var(--color-blue1);
}

.colorArrived {
  color: var(--color-green0);
}

.card-mid-para {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--color-blue2);
  font-size: var(--fs-label);
  font-family: var(--family-bold);
  margin-bottom: 0;
  max-width: 7.5rem;
}
@media (max-width: 710px) {
  .card-mid-para {
    margin-left: 1rem;
  }
}
.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-mid-list {
  list-style: disc !important;
  margin-bottom: 0;
  font-size: 0.7rem;
}

.mid-container-process {
  padding: 0.938rem 0.813rem 0.938rem 0.813rem;
  background-color: var(--color-white);
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
}

.process-page-image > img {
  margin-right: 1rem;
  height: 6.5rem;
  border-radius: 5px;
}

.test-head {
  color: var(--color-gray2);
  font-size: var(--fs-label);
  margin-bottom: 0;
}

.test-para {
  font-size: var(--fs-lg);
  color: var(--color-blue2);
  max-width: 90%;
  font-family: var(--family-bold);
}

.test-section-head {
  color: var(--color-gray2);
  font-size: var(--fs-label);
  margin-bottom: 0;
}

.test-section-para {
  font-size: var(--fs-mid);
  color: var(--color-blue2);
  font-family: var(--family-bold);
}

.test-section {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.test-section div {
  padding: 0 0.4rem;
}

.test-section div:first-child {
  margin-right: 1rem;
}

.last-container {
  background-color: var(--color-white);
  margin: 1rem 0rem;
  border-radius: 5px;
  padding: 49px 39px 16px 39px;
}

.last-container-first-child {
  padding: 0rem 0.5rem;
}

.last-container-child {
  padding: 0.5rem 0rem;
}

.last-sectionlast {
  padding: 0.5rem 0rem;
  width: 33%;
}

.last-container-paraone {
  color: var(--color-gray4);
  font-size: 1.34rem;
  margin-bottom: 0;
  font-family: "siena-light" !important;
}
.last-container-paraone2 {
  color: var(--color-gray4);
  font-size: 1.34rem;
  margin-bottom: 0;
  font-family: "siena-bold" !important;
}

.last-container-childmid {
  font-size: 1.4rem;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-container-childmid-container {
  font-size: var(--fs-label);
  word-wrap: break-word;
}

.sort-box {
  margin-top: 1rem;
}

.div-sort {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.order {
  margin-bottom: 0;
}

.sort-option-div {
  display: flex;
  width: 70%;
  font-size: 0.9rem;
  flex-wrap: wrap;
  align-items: center;
}

.sort-option-div label {
  padding-right: 0.5rem;
  color: var(--color-gray3);
}

.sort-option-div input {
  margin-right: 0.2rem;
}

.filterby-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filterby-options{
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.filterby-div-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.filter-child-div {
  margin-right: 0.5rem;
  width: 5.5rem;
  height: 1.8rem;
  border-radius: 3rem;
  border: 1px solid var(--color-gray4);
  cursor: pointer;
  color: var(--color-gray3);
  text-align: center;
}

.active-mode {
  border: 3px solid #120b5e;
}

.filter-divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-gray4);
  margin: .5rem .1rem;
}

.card-div {
  height: 100%;
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.card-div > ul {
  margin-bottom: 0;
}

.div-details-main {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.payment {
  background-color: var(--color-white);
  padding: 3.063rem 2.438rem 1rem 2.438rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.div-details-payments {
  display: flex;
  flex-wrap: wrap;
}

.payment-heading {
  font-size: var(--fs-label);

  font-family: "siena-bold !important";
}

.div-details-payments div.div-details-child {
  width: 25%;
}

.documents div.div-details-child {
  width: 50%;
}

.div-details-payments div.div-details-child p.color-blue2 {
  font-size: var(--fs-label);
  margin-bottom: 0;
}

.div-details-payments div.div-details-child p.color-grey {
  font-size: var(--fs-label);
}

.div-details-child {
  width: 30%;
}

.last-container-child {
  padding: 0.5rem 0;
}

.color-grey {
  color: var(--color-gray4) !important;
  font-family: "siena-light" !important;
}
.color-grey2 {
  color: var(--color-gray4) !important;
  font-family: "siena-bold" !important;
}

.color-blue {
  color: var(--color-gray7) !important;
}

.color-blue2 {
  color: var(--color-blue2);
}

.map-head {
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  background-color: var(--color-white);
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 4rem;
}

.map-layover {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-image: url(../../../public/image/map-final.png);
  background-size: cover;
  opacity: 0.7;
}

.map-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-img img {
  height: 3rem;
  width: 4rem;
}

.map-line {
  height: 86%;
  width: 0.01rem;
  background-color: var(--color-gray5);
}

.map-card-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-gray5);
}

.map-top-div {
  display: flex;
  align-items: center;
}

.map-top-div p {
  font-size: var(--fs-label);
  margin-bottom: 0;
}

.payment-data {
  font-size: 14px;
  color: var(--color-gray4);
}
.map-milestone-div {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
}

.map-milestone-div-child {
  height: 21rem;
  width: 100%;
  margin-bottom: 1rem;
}

.map-milestone-div-child > div {
  height: 100%;
}

.locate-div {
  height: 100%;
  width: 100%;
  position: relative;
}

.locate-div .gmnoprint {
  display: none;
}

.locate-div > div {
  position: relative !important;
}

.locate-div .gm-style-cc a {
  display: none;
}

.map-main-comp {
  height: 100%;
}

.milestone-div {
  display: flex;
  justify-content: space-around;
}

.noshipmentselected {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.packing-list {
  margin: 0;
}

.packing-table {
  border-spacing: 0 1rem;
  border-collapse: separate;
}

.packing-table th,
.packing-table td {
  padding: 1rem 0.5rem;
  font-family: "siena-medium";
  font-size: var(--fs-label);
  color: var(--color-gray2);
  background-color: var(--color-white1);
}

.packing-head {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.packing-bottom {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
/* -------------------Media Query---------------------- */

@media only screen and (max-width: 1080px) {
  .last-container {
    padding: 16px;
  }
}
@media screen and (max-width: 479px) {
  .filter-icon {
    padding-left: 0;
  }

  .card-mid {
    height: auto;
    align-items: center;
  }

  .card-mid-img img {
    width: 9rem;
    padding-right: 1rem;
    height: 4rem;
  }

  .line {
    height: 95%;
    width: 0.015rem;
  }

  .card-div {
    width: 12rem;
  }

  .btn-style {
    margin-bottom: 1rem;
  }

  .mid-container-process {
    align-items: normal;
    flex-direction: column;
  }

  .process-page-image > img {
    height: 10rem;
    width: 12rem;
  }

  .process-page-image {
    margin-top: 2rem;
  }

  .test-section {
    padding-top: 1rem;
    justify-content: space-evenly;
  }

  .div-details-child {
    width: 50% !important;
  }

  .search-div > .css-i4bv87-MuiSvgIcon-root,
  .filter-icon > .css-i4bv87-MuiSvgIcon-root {
    height: 4rem;
    width: 6rem;
  }

  .map-head {
    height: auto;
  }

  .map-img img {
    height: 6rem;
    width: 6rem;
  }

  .map-line {
    width: 0.4rem;
  }

  .map-card-div > .css-i4bv87-MuiSvgIcon-root {
    height: 4rem;
    width: 8rem;
  }
  .card-mid-list {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .card-mid {
    height: auto;
  }

  .card-mid-img img {
    width: 6rem;
  }

  .card-div {
    width: 7rem;
  }

  .btn-style {
    margin-bottom: 1rem;
  }

  .mid-container-process {
    align-items: normal;
    flex-direction: column;
  }

  .process-page-image > img {
    margin-right: 4rem;
  }

  .test-section div {
    padding: 0 5rem;
  }
  .card-mid-list {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .card-mid {
    height: 5rem;
  }

  .card-mid-img img {
    width: 5rem;
  }

  .card-div {
    width: 9rem;
  }

  .btn-style {
    margin-bottom: 1rem;
  }

  .process-page-image > img {
    margin-right: 3rem;
  }

  .search-div > .css-i4bv87-MuiSvgIcon-root,
  .filter-icon > .css-i4bv87-MuiSvgIcon-root {
    height: 3rem;
    width: 3rem;
  }

  .filter-icon {
    padding-left: 0;
  }

  .map-head {
    height: auto;
  }

  .map-card-div {
    padding-left: 1rem;
  }

  .test-section div {
    padding: 0px 4.4rem;
  }

  .map-img img {
    width: 3rem;
  }

  .milestone-div div:nth-child(3) {
    margin-left: 1rem;
  }
  .card-mid-list {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .input-search {
    padding: 5.5px 0px;
  }

  .card-div {
    width: 7rem;
  }

  .search-icon {
    font-size: var(--fs-mid);
  }

  .filter-icon {
    padding-left: 0;
  }

  .process-page-image > img {
    margin-right: 3rem;
    height: 6rem;
  }

  .mid-container-process {
    align-items: normal;
  }

  .test-section {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 3rem;
  }

  .map-head {
    height: auto;
  }

  .map-top-div {
    align-items: normal;
    flex-direction: column;
    max-width: 60%;
  }

  .map-card-div {
    padding-left: 1rem;
  }
  .milestone-right-side {
    padding-left: 1.2rem;
  }
}
