/* -----DashboardSkeleton CSS---- */
.large-card-box {
  background: none !important;
}

.align-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testdiv {
  display: block;
}
.text-link:hover {
  cursor: pointer;
}
.testdiv2 {
  display: none;
}
.mx-15 {
  margin: 0px 15px;
}

.btn-mobile {
  display: none;
}

.wrapper {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  height: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo img {
  width: 209px;
  height: auto;
}

.wrapper-menu {
  width: 100%;
}

.container-wrapper-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu {
  width: 54%;
  margin: 0px auto;
}

.menu ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu ul li {
  padding: 0px 15px;
  opacity: 0.4;
}

.menu ul li a {
  font-family: "siena-bold";
  color: var(--color-white);
  font-size: 1.25rem;
}

.menu ul .li-active {
  opacity: 1;
}

.notification .user-notification {
  position: relative;
}

.notification .user-notification .name-user {
  color: var(--color-white);
  font-family: "siena-light";
  font-size: 1.25rem;
  font-weight: 300;
}

.notification .icon-user {
  position: relative;
}

.notification .icon-user .button-user {
  background-color: transparent;
  border: none;
}

.notification .icon-user .button-user img {
  margin: 0px 4px;
}

.notification .icon-user .button-user .img-logo-user {
  width: 45px;
  height: auto;
}

.notification .icon-user .button-user .img-arrow-user {
  width: 10px;
  height: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.notification .icon-user .button-user .active-img-arrow-user {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.notification .menu-dropdow {
  display: block;
  width: 110px;
  height: 45px;
  background-color: var(--color-white);
  position: absolute;
  top: 67px;
  right: 20px;
  -webkit-box-shadow: 0px 3px 10px #00000029;
  box-shadow: 0px 3px 10px #00000029;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.notification .menu-dropdow ul li {
  padding: 15px 14px;
}

.notification .menu-dropdow ul li a {
  color: var(--color-gray1);
  font-size: 1rem;
  font-family: "siena-light";
}

.notification .active-menu-dropdow {
  visibility: visible !important;
  opacity: 1;
}

.notification .btn-language button {
  width: 24px;
  height: 24px;
  color: white;
  font-size: 0.7rem;
  background-color: transparent;
  border: 1px solid var(--color-white);
  border-radius: 0px 5px 0px 5px;
  margin: 0px 4px;
}

.notification .btn-language button:active {
  background-color: var(--color-blue1);
  border: 1px solid var(--color-blue1);
}

.notification .icon-bell p {
  display: none;
}

.no-data {
  margin: auto;
}
body {
  background-color: var(--color-white1);
}

.container-section {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 17px;
  /* padding-top: 116px;` */
}

.wrapper-section {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 2fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  -ms-grid-rows: 188px 85px 128px 340px;
  /* grid-template-rows: 188px 85px 0px 340px; */
  grid-template-rows: 188px 85px 128px 340px;
  grid-gap: 10px;
  justify-items: center;
  padding-bottom: 30px;
}

.small-card {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 5px;
}

.small-card .wapper-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 26px 14px 0px 14px;
}

.small-card .wapper-card .text-card {
  margin-left: 17px;
  width: 100%;
}

.small-card .wapper-card .text-card .title-card {
  font-family: "siena-bold";
  font-size: 1.438rem;
  color: var(--color-gray3);
}

.small-card .wapper-card .text-card p {
  font-family: "siena-bold";
  font-size: 4rem;
  margin: 0;
  color: var(--color-gray3);
  padding-top: 8px;
}

.small-card .wapper-card .text-card .text-link {
  font-family: "siena-medium";
  font-size: 1.125rem;
  color: var(--color-gray3);
  padding: 0px;
  margin-top: -3px;
  /* cursor: not-allowed; */
}
.text-card .text-link {
  cursor: not-allowed;
}
.text-link-blue {
  color: var(--color-blue2) !important;
  cursor: pointer !important;
}

.large-card {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border-radius: 5px;
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1/3;
}

.large-card .wrapper-card {
  height: 100%;
  overflow: auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
  grid-template-columns: 1fr 4fr;
  padding: 26px 14px 0px 14px;
}

.large-card .wrapper-card .text-card {
  width: 100%;
}

.large-card .wrapper-card .text-card .title-card {
  font-family: "siena-bold";
  font-size: 1.438rem;
  color: var(--color-gray3);
  margin: 0;
}

.large-card .wrapper-card .text-card p {
  font-family: "siena-bold";
  font-weight: 100;
  font-size: 4rem;
  color: var(--color-gray3);
  margin: 0;
}

.large-card .wrapper-card .text-card p span {
  font-size: 2.5rem;
  color: var(--color-gray3);
  padding-right: 1rem;
}

.large-card .wrapper-card .text-card .signal-add {
  margin-left: 115px;
  font-size: 1.875rem;
  color: var(--color-gray3);
}

.large-card .wrapper-card .text-card .text-information {
  font-family: "siena-medium";
  font-size: 1.125rem;
  padding: 14px 0px;
}

.medium-card {
  width: 100%;
  height: 100%;
  background-color: var(--color-white);
  border-radius: 5px;
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-grid-row: 3;
  grid-row: 3;
}

.medium-card .wrapper-card {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 4fr;
  grid-template-columns: 1fr 4fr;
  padding: 18px 14px 0px 14px;
  cursor: pointer;
}

.medium-card .wrapper-card .text-card {
  width: 100%;
  height: 100%;
}

.medium-card .wrapper-card .text-card .title-card {
  font-family: "siena-bold";
  font-size: 1.438rem;
  color: var(--color-gray2);
  margin: 0;
}

.medium-card .wrapper-card .text-card p {
  font-family: "siena-medium";
  font-weight: 100;
  margin: 5px 0px;
  font-size: 1.5rem;
  color: var(--color-gray4);
}

.medium-card .wrapper-card .text-card p span {
  font-size: 4.438rem;
  color: var(--color-blue3);
}

.medium-card-two {
  width: 100%;
  background-color: var(--color-gray2);
  border-radius: 5px;
  -ms-grid-column: 5;
  grid-column: 5;
  -ms-grid-row: 3;
  grid-row: 3;
  height: 55vh;
}

.medium-card-two .title-table {
  color: var(--color-white2);
  font-family: "siena-light";
  font-size: 1.438rem;
  padding: 18px 10px;
  margin: 0;
}

.medium-card-two .table-hairdresser {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 13px;
}

.medium-card-two .table-hairdresser .title-hairdresser {
  font-family: "siena-light";
  font-size: 1rem;
  color: var(--color-white2);
  margin: 0;

  text-transform: uppercase;
}

.medium-card-two .container-table {
  width: calc(100% - 20px);
  height: 65%;
  margin: 3px auto;
  overflow: auto;
}

.medium-card-two .container-table .itens-table {
  width: calc(100% - 6px);
  height: 53px;
  margin: 3px auto;
  border-radius: 10px;
  background-color: white;
}

.medium-card-two .container-table .itens-table .itens {
  height: 100%;
  padding: 0px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.medium-card-two .container-table .itens-table .itens p {
  font-family: "siena-medium";
  font-size: 1.125rem;
  color: var(--color-gray2);
  padding: 0px 5px;
}

.btn-load-more {
  display: none;
}

.title-list-last-updates {
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1/5;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  width: 100%;
  height: 89px;
  border-radius: 5px;
  background-color: var(--color-white);
}

.title-list-last-updates .container-list {
  padding: 0px 14px 0px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.title-list-last-updates .container-list .information-table {
  width: 100%;
  height: 90%;
  padding: 0px 5px 5px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.title-list-last-updates .container-list .information-table .title {
  font-size: 2rem;
  font-family: "siena-semibold";
  color: var(--color-gray2);
}

.title-list-last-updates .container-list .information-table .title span {
  font-size: 1.375rem;
}

.title-list-last-updates .container-list .information-table .description-table {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.8fr 3.3fr 1.5fr 1.7fr 1.4fr;
  grid-template-columns: 1.5fr 3fr 1.5fr 1fr 1.1fr;
}

.title-list-last-updates
  .container-list
  .information-table
  .description-table
  p {
  font-family: "siena-light";
  font-size: 1rem;
  color: var(--color-gray4);
  text-transform: uppercase;
}

.list-last-updates {
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
  grid-column: 1/5;
  -ms-grid-row: 3;
  -ms-grid-row-span: 2;
  grid-row: 3/5;
  width: 100%;
  border-radius: 5px;
  overflow: auto;
  height: 55vh;
}

.list-last-updates .list-items {
  background-color: var(--color-white);
  border-radius: 5px;
  margin: 3px auto;
  cursor: pointer;
}

.list-last-updates .list-items .container-list {
  padding: 10px 14px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 75px 1.8fr 3.3fr 1.5fr 1.7fr 1.4fr;
  grid-template-columns: 75px 1.5fr 3fr 1.5fr 1fr 1.1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-last-updates .list-items .container-list img {
  width: 55px;
  height: auto;
}

.list-last-updates .list-items .container-list .from {
  font-family: "siena-medium";
  font-size: 1.22rem;
  color: var(--color-gray2);
  padding: 0px 5px 6px 0px;
  margin: 0 !important;
}

.list-last-updates .list-items .container-list .to {
  font-family: "siena-semibold";
  font-size: 1.25rem;
  color: var(--color-blue2);
}

.list-last-updates .list-items .container-list .btn-dropdown-list-items {
  display: none;
}

.list-last-updates .list-items .container-list .destination {
  border-left: 1px solid var(--color-gray5);
  padding-left: 10px;
}

.list-last-updates .list-items .container-list .ship {
  padding-left: 10px;
}

.list-last-updates .list-items .container-list .po-number {
  padding-left: 7px;
}

.container-list p {
  margin: 0;
}

.list-last-updates .list-items:hover {
  background-color: var(--color-gray6);
}

.position-grid-title-po-number,
.position-grid-title-ship,
.position-grid-title-eta,
.position-grid-title-etd,
.position-grid-title-destination {
  display: none;
}

.inactive {
  display: none;
}
/* -----MEDIA QUERIES---- */

@media only screen and (min-width: 759px) and (max-width: 1340px) {
  .medium-card-two,
  .large-card {
    height: 36vh;
  }
  .medium-card-two .table-hairdresser .title-hairdresser {
    font-size: 1rem !important;
  }
  .title-list-last-updates {
    margin-top: 42px;
  }
  /* .list-last-updates {
    margin-top: -32px;
  } */
}
@media only screen and (min-width: 762px) and (max-width: 925px) {
  .list-last-updates .list-items .container-list .po-number {
    padding-left: 30px;
  }

  .list-last-updates .list-items .container-list .eta {
    padding-left: 15px;
  }

  .list-last-updates .list-items .container-list .etd {
    padding-left: 47px;
  }
}

@media (max-width: 1385px) {
  .notification .user-notification .name-user {
    display: none;
  }

  .menu {
    width: 65%;
  }

  .logo img {
    width: 175px;
  }
}

@media (max-width: 1170px) {
  .notification .user-notification .name-user {
    display: block;
  }

  .logo img {
    width: 180px;
    height: auto;
  }

  .wrapper {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .btn-mobile {
    display: block;
  }

  .btn-mobile button {
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
  }

  .btn-mobile button .btn-open,
  .btn-mobile button .btn-close {
    display: none;
    width: 25px;
    height: auto;
    margin: 0 auto;
  }

  .btn-mobile button .btn-active {
    display: block;
  }

  .wrapper-menu {
    width: 0px;
    height: calc(100vh - 100px);
    position: absolute;
    top: 100px;
    left: 0;
    overflow: hidden;
  }

  .wrapper-menu.active-menu {
    width: 260px;
  }

  .wrapper-menu.active-menu .container-wrapper-menu {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  .container-wrapper-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: var(--color-blue2);
    height: 100%;
    width: 100%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .menu {
    width: 100%;
    padding: 48px 0px;
  }

  .menu ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    padding: 0px 34px;
  }

  .menu ul li {
    padding: 0;
    padding-bottom: 48px;
  }

  .notification {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 0px 20px;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
  }

  .notification div {
    margin: 25px 0px;
  }

  .notification .user-notification {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
  }

  .notification .user-notification div {
    margin: 0px;
  }

  .notification .icon-user .button-user img {
    margin: 0;
    padding-right: 10px;
  }

  .notification .icon-user .button-user .img-arrow-user {
    display: none;
  }

  .notification .menu-dropdow {
    width: auto;
    height: auto;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: static;
    visibility: visible;
    opacity: 1;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .notification .menu-dropdow ul li {
    padding: 5px 0px;
  }

  .notification .menu-dropdow ul li a {
    color: var(--color-white);
    font-size: 1rem;
    font-family: "siena-light";
    border-bottom: 1px solid var(--color-white);
  }

  .notification .icon-bell img {
    padding: 0px 10px;
  }

  .notification .icon-bell p {
    display: block;
    color: var(--color-white);
    font-family: "siena-light";
    font-size: 1.125rem;
    font-weight: 300;
  }

  .btn-language {
    margin-left: 8px !important;
  }
}

@media (max-width: 768px) {
  .wrapper-menu.active-menu {
    width: 198px;
  }

  .menu {
    width: 100%;
    padding: 0px;
    padding-top: 18px;
  }

  .menu ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    padding: 0px 16px;
  }

  .menu ul li {
    padding: 0;
    padding-bottom: 40px;
  }

  .notification {
    padding: 0px 20px 50px 20px;
  }

  .notification div {
    margin: 15px 0px;
  }

  .btn-mobile button {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (min-width: 1900px) and (max-width: 1950px) {
  .small-card .wapper-card .text-card p {
    font-size: 4.5rem !important;
  }

  .small-card .wapper-card .text-card {
    margin-left: 17px !important;
    line-height: 60px !important;
  }

  .small-card .wapper-card .text-card .text-link {
    font-size: 1.6rem !important;
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 2.5rem;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1.8rem;
  }

  .title-list-last-updates .container-list .information-table {
    line-height: 10px;
  }

  .title-list-last-updates
    .container-list
    .information-table
    .description-table
    p {
    font-size: 1.2rem;
  }

  .medium-card-two .title-table {
    font-size: 1.938rem;

    font-weight: bold;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-size: 1.2rem;

    font-weight: bold;
    margin: 0;
  }
}

@media (min-width: 1900px) {
  .wrapper-section {
    -ms-grid-rows: 170px 85px 0px 340px;
    /* grid-template-rows: 170px 85px 128px 340px; */
  }

  .small-card .wapper-card .text-card {
    margin-left: 10px;
    line-height: 52px;
  }

  .small-card .wapper-card .text-card p {
    font-size: 3.5rem;
  }

  .large-card .wrapper-card .text-card p {
    font-size: 3.5rem;
  }

  .medium-card .wrapper-card .text-card .title-card {
    font-size: 2rem;
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 1.5rem;
  }

  .small-card .wapper-card .text-card .title-card {
    font-size: 1.738rem;
  }

  .small-card .wapper-card .text-card p {
    font-size: 4.2rem;
  }

  .small-card .wapper-card .text-card .text-link {
    font-size: 1.4rem;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 1.738rem;
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 2.5rem;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1.8rem;
  }

  .title-list-last-updates .container-list .information-table {
    line-height: 10px;
  }

  .title-list-last-updates
    .container-list
    .information-table
    .description-table
    p {
    font-size: 1.2rem;
  }

  .medium-card-two .title-table {
    font-size: 1.938rem;

    font-weight: bold;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-size: 1.2rem;

    font-weight: bold;
    margin: 0;
  }
}

@media only screen and (min-width: 1651px) and (max-width: 1989px) {
  .wrapper-section {
    -ms-grid-rows: 170px 85px 0px 340px;
    /* grid-template-rows: 170px 85px 128px 340px; */
  }

  .small-card .wapper-card .text-card {
    margin-left: 10px;
    line-height: 52px;
  }

  .small-card .wapper-card .text-card p {
    font-size: 3.5rem;
  }

  .large-card .wrapper-card .text-card p {
    font-size: 3.5rem;
  }

  .medium-card .wrapper-card .text-card .title-card {
    font-size: 2rem;
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 1.5rem;
  }

  .small-card .wapper-card .text-card .title-card {
    font-size: 1.738rem;
  }

  .small-card .wapper-card .text-card p {
    font-size: 4.2rem;
  }

  .small-card .wapper-card .text-card .text-link {
    font-size: 1.4rem;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 1.738rem;
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 2.5rem;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1.8rem;
  }

  .title-list-last-updates .container-list .information-table {
    line-height: 10px;
  }

  .title-list-last-updates
    .container-list
    .information-table
    .description-table
    p {
    font-size: 1.2rem;
  }

  .medium-card-two .title-table {
    font-size: 1.938rem;

    font-weight: bold;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-size: 1.2rem;

    font-weight: bold;
    margin: 0;
  }

  /* .test {
    margin-top: -187px;
  } */
}

@media (max-width: 1650px) {
  .wrapper-section {
    -ms-grid-rows: 170px 85px 0px 340px;
    grid-template-rows: 170px 85px 0px 340px;
  }

  .small-card .wapper-card .text-card {
    margin-left: 10px;
    line-height: 52px;
  }

  .small-card .wapper-card .text-card p {
    font-size: 3.5rem;
  }

  .large-card .wrapper-card .text-card p {
    font-size: 3.5rem;
  }

  .medium-card .wrapper-card .text-card .title-card {
    font-size: 2rem;
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 1.5rem;
  }

  .small-card .wapper-card .text-card .title-card {
    font-size: 1.738rem;
  }

  .small-card .wapper-card .text-card p {
    font-size: 4.2rem;
  }

  .small-card .wapper-card .text-card .text-link {
    font-size: 1.4rem;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 1.738rem;
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 2.5rem;
    margin-top: -4px;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1.8rem;
  }

  .title-list-last-updates .container-list .information-table {
    line-height: 10px;
  }

  .title-list-last-updates
    .container-list
    .information-table
    .description-table
    p {
    font-size: 1.2rem;
  }

  .medium-card-two .title-table {
    font-size: 1.938rem;

    font-weight: bold;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-size: 1.2rem;

    font-weight: bold;
    margin: 0;
  }
}

@media (max-width: 1585px) {
  .small-card .wapper-card .text-card .title-card {
    font-size: 1.3rem;
  }
}

@media (max-width: 1500px) {
  .small-card .wapper-card .text-card .title-card {
    font-size: 1.35rem;
  }
}

@media (max-width: 1450px) {
  .wrapper-section {
    -ms-grid-rows: 155px 85px 0px 340px;
    grid-template-rows: 155px 85px 0px 340px;
  }

  .small-card .wapper-card .text-card p {
    font-size: 3rem;
  }

  .small-card .wapper-card .text-card .text-link {
    font-size: 1.5rem;
  }

  .large-card .wrapper-card .text-card p {
    font-size: 3rem;
  }
}

@media (max-width: 1390px) {
  .small-card .wapper-card .text-card .title-card {
    font-size: 1.4rem;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 1.938rem;
  }
}

@media (max-width: 1340px) {
  .wrapper-section {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 155px 264px 0px 88px;
    grid-template-rows: 155px 264px 0px 128px;
  }

  .small-card .wapper-card .text-card .title-card {
    font-size: 1.938rem;
  }

  .large-card {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    grid-row: 2/3;
  }

  .medium-card {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .medium-card-two {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    grid-column: 3/5;
    -ms-grid-row: 2;
    -ms-grid-row-span: 2;
    grid-row: 2/4;
    margin-top: 0px;
  }

  .title-list-last-updates {
    -ms-grid-row: 4;
    grid-row: 4;
  }

  .list-last-updates {
    -ms-grid-row: 5;
    grid-row: 5;
  }
}

@media (max-width: 1070px) {
  .small-card .wapper-card .text-card .title-card {
    font-size: 1.538rem;
  }
}

@media (max-width: 925px) {
  .small-card .wapper-card .text-card {
    margin-left: 10px;
  }

  .small-card .wapper-card .text-card .title-card {
    font-size: 1.538rem;
  }

  .small-card .wapper-card .text-card p {
    font-size: 2.5rem;
  }

  .small-card-img,
  .large-card-img,
  .medium-card-img,
  .list-card-img {
    width: 60px;
  }

  .medium-card-two .container-table .itens-table .itens p {
    font-size: 1rem;
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 1rem;
  }

  .list-last-updates .list-items .container-list {
    -ms-grid-columns: 75px 1.8fr 3.3fr 1.5fr 1.7fr 1.6fr;
    grid-template-columns: 75px 1.8fr 3.3fr 1.5fr 1.7fr 1.6fr;
  }

  .list-last-updates .list-items .container-list .from {
    font-size: 1rem;
  }

  .list-last-updates .list-items .container-list .to {
    font-size: 1.1rem;
  }
}

@media (max-width: 840px) {
  .small-card .wapper-card {
    padding: 26px 5px 0px 5px;
  }

  .small-card .wapper-card .text-card p {
    font-size: 2rem;
  }

  .small-card .wapper-card .wrapper-section {
    -ms-grid-rows: 130px 264px 128px;
    grid-template-rows: 130px 264px 128px;
  }

  .small-card-img,
  .large-card-img,
  .medium-card-img,
  .list-card-img {
    width: 55px;
  }
}

@media (max-width: 758px) {
  .wrapper-section {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 107px 107px 103px 87px;
    grid-template-rows: 107px 107px 103px 0px;
    grid-gap: 16px;
  }

  .small-card .wapper-card {
    text-align: center;
    padding: 14px 14px 0px 14px;
  }

  .small-card .wapper-card .text-card {
    line-height: 26px;
  }

  .small-card .wapper-card .text-card .text-link {
    font-size: 1.538rem;
    padding: 3px 0px 0px 0px;
  }

  .small-card .wapper-card .text-card p {
    padding: 0px 0px;
  }

  .large-card {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .large-card .wrapper-card {
    padding: 14px 14px 0px 14px;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 1rem;
    padding-bottom: 10px;
  }

  .large-card .wrapper-card .text-card p {
    display: inline;
    font-size: 1.375rem;
  }

  .large-card .wrapper-card .text-card p span {
    font-size: 1.375rem;
  }

  .large-card .wrapper-card .text-card .signal-add {
    margin-left: 0px;
    margin: 0px 20px;
    font-size: 1.5rem;
  }

  .large-card .wrapper-card .text-card .text-information {
    display: block;
    font-size: 0.875rem;
    padding: 10px 0px;
  }

  .medium-card {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 4;
    grid-row: 4;
  }

  .medium-card .wrapper-card .text-card p span {
    font-size: 2rem;
  }

  .medium-card-two {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 5;
    grid-row: 5;
    margin-top: -16px;
    height: 38vh;
  }

  .medium-card-two .btn-load-more {
    display: block;
    padding: 8px 25px;
    color: var(--color-white);
    font-size: 0.875rem;
    border: 1px solid var(--color-white);
    background-color: transparent;
    border-radius: 50px;
    margin: 13px auto;
  }

  .medium-card-two .title-table {
    color: var(--color-white2);
    font-family: "siena-light";
    font-size: 1.438rem;
    padding: 11px 16px;
  }

  .medium-card-two .table-hairdresser {
    padding: 0px 16px;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-family: "siena-light";
    font-size: 0.875rem;
    color: var(--color-white2);
  }

  .medium-card-two .container-table {
    height: auto;
  }

  .medium-card-two .container-table .itens-table {
    margin: 8px auto;
  }

  .title-list-last-updates {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 6;
    grid-row: 6;
  }

  .title-list-last-updates .container-list .information-table {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .title-list-last-updates
    .container-list
    .information-table
    .description-table {
    display: none;
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 1rem;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1rem;
  }

  .list-last-updates {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 7;
    grid-row: 7;
    overflow: visible;
    grid-gap: 8px;
  }

  .list-last-updates .list-items {
    margin: 8px auto;
  }

  .list-last-updates .list-items .container-list {
    padding: 16px 14px 10px 16px;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    -ms-grid-columns: 30px 40px 73px 30px 100px;
    grid-template-columns: 30px 40px 73px 30px 100px;
    -ms-grid-rows: 14px 50px 36px 30px 17px 14px 32px 16px;
    grid-template-rows: 14px 50px 36px 30px;
    gap: 1px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .list-last-updates .list-items .container-list.is-open {
    height: 241px;
  }

  .list-last-updates .list-items .container-list .to {
    font-size: 1.25rem;
  }

  .list-last-updates .list-items .container-list .btn-dropdown-list-items {
    display: block;
    -ms-grid-column: 7;
    grid-column: 7;
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    grid-row: 1/5;
    border: 0;
    background-color: transparent;
    text-align: end;
  }

  .list-last-updates
    .list-items
    .container-list
    .btn-dropdown-list-items
    .img-arrow-dropdown {
    width: 25px;
    height: auto;
  }

  .list-last-updates .list-items .container-list .list-last-updates-img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
  }

  .list-last-updates .list-items .container-list .title-table {
    display: block;
    font-family: "siena-light";
    font-size: 0.875rem;
    color: var(--color-gray4);
  }

  .list-last-updates
    .list-items
    .container-list
    .position-grid-title-destination {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 1;
    grid-row: 1;
  }

  .list-last-updates .list-items .container-list .destination {
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
    grid-column: 3/6;
    -ms-grid-row: 2;
    grid-row: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-left: 0;
    padding: 0px;
  }

  .list-last-updates .list-items .container-list .destination .to {
    margin-left: 10px;
  }

  .list-last-updates .list-items .container-list .position-grid-title-etd {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .etd {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .position-grid-title-eta {
    -ms-grid-column: 4;
    grid-column: 4;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .eta {
    -ms-grid-column: 5;
    grid-column: 5;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .line-list {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray5);
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    grid-column: 1/8;
    -ms-grid-row: 5;
    grid-row: 5;
  }

  .list-last-updates .list-items .container-list .position-grid-title-ship {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 6;
    grid-row: 6;
  }

  .list-last-updates .list-items .container-list .ship {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    -ms-grid-row: 7;
    grid-row: 7;
  }

  .list-last-updates
    .list-items
    .container-list
    .position-grid-title-po-number {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1/5;
    -ms-grid-row: 8;
    grid-row: 8;
  }

  .list-last-updates .list-items .container-list .po-number {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1/5;
    -ms-grid-row: 9;
    grid-row: 9;
  }

  .list-last-updates .btn-load-more {
    display: block;
    padding: 8px 25px;
    color: var(--color-blue1);
    font-size: 0.875rem;
    border: 1px solid var(--color-blue1);
    background-color: transparent;
    border-radius: 50px;
    margin: 13px auto;
  }

  .list-last-updates .list-items .container-list img {
    width: 44px;
  }

  .list-last-updates .list-items .container-list .from {
    padding: 0px;
  }
  .testdiv2 {
    display: block;
  }
  .description-table {
    display: none;
  }
}

@media only screen and (min-width: 451px) and (max-width: 690px) {
  .large-card .wrapper-card {
    padding: 10px 0px 0px 10px;
  }

  .large-card .wrapper-card .text-card {
    padding-left: 12px;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 2rem;
    padding-bottom: 2px;
  }

  .large-card .wrapper-card .text-card p {
    display: inline;
    font-size: 1.575rem;
  }

  .large-card .wrapper-card .text-card p span {
    font-size: 1.575rem;
  }

  .large-card .wrapper-card .text-card .signal-add {
    margin-left: 0px;
    margin: 0px 18px;
    font-size: 1.9rem;
  }

  .large-card .wrapper-card .text-card .text-information {
    display: block;
    font-size: 1.875rem;
    /* padding: 10px 0px; */
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 2rem;
  }

  .medium-card-two .title-table {
    color: var(--color-white2);
    font-family: "siena-light";
    font-size: 1.838rem;
    padding: 11px 16px;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-family: "siena-light";
    font-size: 1.4rem;
    color: var(--color-white2);
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 2rem;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1.6rem;
  }
}

@media (max-width: 450px) {
  .wrapper-section {
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    grid-gap: 10px;
  }

  .small-card-img,
  .large-card-img,
  .medium-card-img,
  .list-card-img,
  .list-last-updates-img {
    width: 42px;
  }

  .small-card .wapper-card {
    text-align: start;
    padding: 14px;
  }

  .large-card .wrapper-card {
    -ms-grid-columns: 1fr 6fr;
    grid-template-columns: 1fr 6fr;
    grid-gap: 10px;
  }

  .large-card .wrapper-card .text-card .signal-add {
    margin-left: 0px;
    margin: 0px 5px;
    font-size: 1.5rem;
  }

  .medium-card .wrapper-card {
    -ms-grid-columns: 1fr 6fr;
    grid-template-columns: 1fr 6fr;
    padding: 18px 14px 14px 14px;
    grid-gap: 10px;
  }

  .medium-card .wrapper-card .text-card .title-card {
    font-size: 1rem;
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 0.875rem;
  }

  .large-card .wrapper-card {
    padding: 10px 0px 0px 10px;
  }

  .large-card .wrapper-card .text-card {
    padding-left: 12px;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 2rem;
    padding-bottom: 2px;
  }

  .large-card .wrapper-card .text-card p {
    display: inline;
    font-size: 1.575rem;
  }

  .large-card .wrapper-card .text-card p span {
    font-size: 1.575rem;
  }

  .large-card .wrapper-card .text-card .signal-add {
    margin-left: 0px;
    margin: 0px 18px;
    font-size: 1.9rem;
  }

  .large-card .wrapper-card .text-card .text-information {
    display: block;
    font-size: 1.875rem;
    /* padding: 10px 0px; */
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 2rem;
  }

  .medium-card-two .title-table {
    color: var(--color-white2);
    font-family: "siena-light";
    font-size: 1.838rem;
    padding: 11px 16px;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-family: "siena-light";
    font-size: 1.4rem;
    color: var(--color-white2);
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 2rem;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1.6rem;
  }
}

@media (max-width: 390px) {
  .list-last-updates .list-items .container-list {
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    -ms-grid-columns: 30px 40px 60px 30px 100px;
    grid-template-columns: 30px 40px 60px 30px 100px;
  }

  .large-card .wrapper-card {
    grid-gap: 10px;
  }

  .large-card .wrapper-card .text-card p {
    font-size: 1.2rem;
  }

  .large-card .wrapper-card .text-card p span {
    font-size: 1.2rem;
  }

  .medium-card .wrapper-card {
    -ms-grid-columns: 1fr 6fr;
    grid-template-columns: 1fr 6fr;
  }

  .medium-card-two .container-table .itens-table .itens p {
    font-size: 0.875rem;
  }

  .large-card .wrapper-card {
    padding: 10px 0px 0px 10px;
  }

  .large-card .wrapper-card .text-card {
    padding-left: 12px;
  }

  .large-card .wrapper-card .text-card .title-card {
    font-size: 2rem;
    padding-bottom: 2px;
  }

  .large-card .wrapper-card .text-card p {
    display: inline;
    font-size: 1.575rem;
  }

  .large-card .wrapper-card .text-card p span {
    font-size: 1.575rem;
  }

  .large-card .wrapper-card .text-card .signal-add {
    margin-left: 0px;
    margin: 0px 18px;
    font-size: 1.9rem;
  }

  .large-card .wrapper-card .text-card .text-information {
    display: block;
    font-size: 1.875rem;
    /* padding: 10px 0px; */
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 2rem;
  }

  .medium-card-two .title-table {
    color: var(--color-white2);
    font-family: "siena-light";
    font-size: 1.838rem;
    padding: 11px 16px;
  }

  .medium-card-two .table-hairdresser .title-hairdresser {
    font-family: "siena-light";
    font-size: 1.4rem;
    color: var(--color-white2);
  }

  .title-list-last-updates .container-list .information-table .title {
    font-size: 2rem;
  }

  .title-list-last-updates .container-list .information-table .title span {
    font-size: 1.6rem;
  }
}
@media (max-width: 758px) {
  .testdiv {
    display: none;
  }
}

/* ------------------------- */
@media only screen and (min-width: 1341px) and (max-width: 1536px) {
  .text-link-data {
    margin-top: -7px;
  }
  .text-link-card {
    margin-bottom: 0;
  }
  .text-link-count {
    padding-top: 0px;
  }
}

@media only screen and (min-width: 1586px) and (max-width: 1651px) {
  .text-link-data {
    margin-top: -7px;
  }
  .text-link-card {
    margin-bottom: 0;
  }
  .text-link-count {
    padding-top: 0px;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 1950px) {
  .text-link-data,
  .text-num {
    margin-top: -7px;
  }
  .text-link-card,
  .text-status {
    margin-bottom: 0;
  }
  .text-link-count,
  .text-count {
    padding-top: 0px;
  }
}
