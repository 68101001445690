.drawer-nav {
  height: 100px;
}

.small-screen-logo {
  padding: 5px 0rem 5px 0;
  height: 6rem;
}

.drawer-nav div.MuiBox-root div.MuiPaper-root.MuiPaper-elevation {
  height: 100%;
}

.active-lang {
  background-color: var(--color-blue1) !important;
  border-color: var(--color-blue1) !important;
}

.drawer-nav div.MuiBox-root nav.MuiPaper-root {
  height: 100px;
  border-bottom: 10px solid var(--color-blue1);

  box-shadow: none !important;
}

.mui-box-div {
  /* width: 80%; */
  width: 100%;
}

.drawer-nav svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  height: 4rem;
  width: 4rem;
}

.header-page {
  justify-content: space-between;
}

.header-page-first-child {
  display: flex;
  justify-content: space-between;
}
.header-page-div {
  width: 52%;
  margin: 0px auto;
}
.header-button {
  background: none;
  border: none;
  font-size: var(--fs-seven);
  font-family: var(--family-light);
}
.header-btn {
  background: none;
  border: none;
  font-size: var(--fs-seven);
  font-family: var(--family-bold);
  padding-right: 3.8rem;
}

.drawer-nav
  nav.MuiPaper-root
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  color: #ffffff6b;
  font-size: var(--fs-seven);
  font-family: var(--family-bold);
}

.drawer-nav
  nav.MuiPaper-root
  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
  background: none !important;
}

div.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  background-color: var(--color-blue2);
  width: 300px;
  color: var(--color-white);
}

h6.MuiTypography-root.MuiTypography-h6 {
  position: sticky;
  top: 0;
  background: var(--color-blue2);
  z-index: 1;
  padding: 16px;
  margin: 0;
  border-bottom: 1px solid var(--color-white);
}

hr.MuiDivider-root {
  display: none;
}

div.MuiDrawer-paper.MuiDrawer-paperAnchorLeft
  span.MuiTypography-root.MuiTypography-body1 {
  font-size: 1.5rem;
}

.user-notification,
.user-main,
.header-page {
  display: flex;
  align-items: center;
}

.user-main {
  padding-left: 2rem;
}

.user-notification > p {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-right: 2rem;
  font-size: var(--fs-value);
  font-family: var(--family-light);
  font-size: var(--fs-one);
  color: var(--color-white);
}

.user-notification button {
  background-color: transparent;
  border: none;
  padding-right: 10px;
}

.bell-icon {
  margin-right: 2.27rem;
  margin-left: 1.8rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  /* align-items: center; */
}

.header-button > button {
  width: 24px;
  height: 24px;
  color: white;
  font-size: 0.9rem;
  background-color: transparent;
  border: 1px solid var(--color-white);
  border-radius: 0px 5px 0px 5px;
  margin: 0px 4px;
}

.header-button > button:hover {
  background-color: var(--color-blue1);
  border-color: var(--color-blue1);
}
.img-arrow-user {
  width: 10px;
  height: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.img-arrow-user {
  width: 10px;
  height: auto;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.active-img-arrow-user {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.main-div-small {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.main-div-small + div img {
  height: auto;
  width: 244px;
}

.Cross-img {
  text-align: left;
  padding-left: 1rem;
}

.Cross-img > svg {
  height: 4rem;
  width: 4rem;
}

.logoutButton {
  position: absolute;
  background-color: var(--color-white);
  height: 3rem;
  width: 9rem;
  top: 4rem;
  right: 11rem;
  display: flex;
  /* justify-content: center; */
  text-align: center;
  padding: 15px 14px;
}

.logoutButton p {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  align-items: center;
  cursor: pointer;
  color: var(--color-gray1);
  font-size: 1.2rem;
  font-family: "siena-light";
}

.logoutButton-small {
  display: flex;
  justify-content: flex-end;
  padding-right: 10rem;
  text-align: center;
}

.logoutButton-small > div {
  background-color: var(--color-white);
  height: 3rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  text-align: center;
}

.logoutButton-small p {
  color: black;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  align-items: center;
  cursor: pointer;
}

.drawer-nav .container > div.css-hyum1k-MuiToolbar-root {
  min-height: auto;
}

@media screen and (min-width: 300px) {
  .logoutButton-small {
    padding-right: 14rem;
  }
}

@media screen and (max-width: 400px) {
  .small-screen-logo {
    height: 5rem;
  }
}

@media screen and (min-width: 580px) {
  .logoutButton-small {
    padding-right: 10rem;
  }
}

@media screen and (min-width: 1200px) {
  .small-screen-logo {
    display: none;
  }

  .main-div-small {
    display: none;
  }

  .logoutButton {
    top: 5rem;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1900px) {
  .header-btn {
    padding-right: 0;
  }
}
@media (max-width: 1600px) {
  .header-btn {
    font-size: 1.55rem;
    padding: 0px 12px;
  }
  .user-name {
    font-size: 1.45rem !important;
  }

  .fJeHri {
    padding: 0 !important;
    background-color: var(--color-white1);
    /* height: calc(100% - 100px);
    overflow-y: auto; */
    margin: 0 auto;
    padding-top: 15px !important;
  }
  .user-main {
    padding-left: 2rem;
    position: relative;
    right: 15px;
  }
  .header-page-div {
    width: 54%;
    margin: 0px auto;
  }
}
@media (min-width: 1900px) {
  .header-btn {
    font-size: 1.55rem;
    padding: 0px 12px;
  }
  .user-name {
    font-size: 1.45rem !important;
  }

  .fJeHri {
    padding: 0 !important;
    background-color: var(--color-white1);
    height: calc(100% - 100px);
    overflow-y: auto;
    margin: 0 auto;
    padding-top: 15px !important;
  }
  .user-main {
    padding-left: 2rem;
    position: relative;
    right: 15px;
  }
  .header-page-div {
    width: 54%;
    margin: 0px auto;
  }
}
