.no-data {
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-md);
  color: var(--color-blue2);
}

.no-access {
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-md);
  color: var(--color-blue2);
}
