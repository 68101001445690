.section-login {
  width: 100%;
  height: 100%;
  background-image: url("/public/icon/banner.jpg");
  background-size: 100% 160%;
  background-repeat: no-repeat;
  background-position: 0% 0%;
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (max-width: 1024px) {
  .section-login {
    background-size: 190% 120%;
    background-position: 50% 25%;
  }
}

@media (max-width: 425px) {
  .section-login {
    background-position: 50% 80%;
  }
}
