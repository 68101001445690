.table-container {
  height: 100vh;
  width: 92vw;
  /* background-color: gray; */
  align-items: center;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
}

th {
  width: 400px;
}

thead {
  /* background-color: ; */
}
