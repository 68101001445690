.report-headline {
  background-color: var(--color-white);
  border-radius: 5px;
  border-bottom: 5px solid var(--color-blue2);
  font-size: var(--fs-value);
  color: var(--color-blue2);
  text-align: center;
  margin: 0;
  font-family: var(--family-medium);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.report-sec {
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 50px;
}

.thresold-add-btn {
  cursor: pointer;
}

.report-headline {
  display: flex;
  justify-content: flex-start;
}

.report-headline p {
  margin: 5px 0px 5px 0px;
  padding-left: 1.5rem;
  font-size: 20px;
}

.data {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  font-size: var(--fs-value);
  color: var(--color-gray4);
  margin-top: 0.4rem;
  font-family: var(--family-bold);
  cursor: pointer;
}

.data:hover {
  background-color: var(--color-lightgray);
}

.data p {
  margin-bottom: 0;
  font-size: 20px;
  padding: 5px;
}

.report {
  position: sticky;
  top: 0;
}

.active-color {
  color: var(--color-blue2) !important;
}

/* -------Overview---------- */
.overview {
  flex: 1;
}

.head-section {
  margin-right: 1rem;
  height: 100%;
  flex: 1;
}

.chart-size {
  padding-top: 2rem;
}

.heading {
  font-size: var(--fs-md);
  color: var(--color-blue2);
  margin-bottom: 1rem;
}

.type {
  color: var(--color-gray4);
  padding: 1.5rem;
  background-color: var(--color-white);
  height: 40%;
  border-radius: 0.5rem;
  font-size: var(--fs-value);
}

.mode {
  color: var(--color-gray4);
  padding: 1rem;
  background-color: var(--color-white);
  height: 48%;
  border-radius: 0.5rem;
  margin-top: 1rem;
  font-size: var(--fs-value);
}

.card-overview {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding: 40px 72px 40px 72px;
}

.card-overview img {
  height: 5rem;
  width: 5rem;
}

.text-overview {
  padding-left: 3rem;
}

.text-overview p {
  margin-bottom: 0;
}

.pre-sec {
  font-size: var(--fs-title);
  font-family: var(--family-bold);
  color: var(--color-gray2);
}

.num-sec {
  color: var(--color-blue1);
  font-size: var(--fs-four);
  font-family: var(--family-bold);
}

.data-sea {
  font-size: 5rem;
  color: var(--color-blue1);
  margin: 0;
  font-family: var(--family-bold);
}

.data-air {
  font-size: var(--fs-lg);
  color: var(--color-blue2);
  margin: 0;
  font-family: var(--family-bold);
}

.title-as {
  font-size: var(--fs-label);
  color: var(--color-gray4);
  margin: 0;
}

/* -----Transport---- */

.head-trans {
  font-size: var(--fs-seven);
  color: var(--color-blue2);
  margin: 0;
  font-family: var(--family-bold);
  margin-top: -11px;
}

.Transport-section {
  background-color: var(--color-white);
  padding: 1rem 1rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
}
@media (min-width: 992px) {
  .calender-section {
    margin-right: 1.5rem;
    padding-right: 6%;
  }
}

.calender {
  border: none;
  border-bottom: 1px solid var(--color-white1);
  font-family: "siena-regular";
  font-size: 12px;
  color: var(--color-gray2);
  margin-right: 4rem;
}

.props-type {
  color: var(--color-gray1);
  text-transform: uppercase;
  margin: 0;
  margin-right: 1rem;
  font-size: 1.12rem;
  font-family: "siena-regular";
}

.props-text {
  text-transform: uppercase;
  color: var(--color-gray0);
  cursor: pointer;
  font-size: 1.12rem;
  margin: 0;
  margin-right: 1rem;
  font-family: "siena-regular";
}

.btn-filter {
  color: var(--color-blue1);
  border: 1px solid var(--color-blue1);
  background-color: transparent;
  border-radius: 50px;
  margin-right: 1rem;
  padding: 6px 15px;
  font-size: 0.9rem;
}

.btn-reset {
  border: none;
  font-family: "siena-medium";
  padding: 0;
  margin-right: 1rem;
  font-size: 1.2rem;
}

.head-transport {
  background-color: var(--color-white);
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 10px solid var(--color-blue2);
  min-width: 20%;
}

.head-transport p {
  margin: 0;
  width: 100%;
  text-align: center;
  font-family: var(--family-medium);
  font-size: var(--fs-value);
  cursor: pointer;
}
.transport-mid {
  width: 50%;
}
.transport-mid-car {
  width: 50%;
}
.transport-mid-sec {
  margin: 1rem 0;
}

.transport-bottom {
  margin-bottom: -14px;
}

.search-section {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 0.5rem;
  min-width: 40%;
}

.search-filter {
  display: flex;
  justify-content: space-around;
}

/* ----datexport------ */
.btn-section {
  border-radius: 1.5rem;
  background-color: var(--color-blue1);
  border: none;
  color: var(--color-white);
  padding: 0.5rem 1rem;
}

.export-section {
  background-color: var(--color-white);
  margin-top: 0.5rem;
  border-radius: 5px;
  padding: 24px 31px;
}

.mid-export-section {
  padding: 2rem 0rem;
}

.date-sec {
  color: var(--color-gray4);
  font-family: var(--family-bold);

  font-size: 1.4rem;
}

.checkbox-section {
  padding: 1rem 0rem;
}

.checkbox-title {
  color: var(--color-blue2);
  cursor: pointer;
  font-family: var(--family-bold);
  margin: 0;
  font-size: 1.5rem;
}

.checkbox-text {
  color: var(--color-gray4);
  line-height: 26px;
  font-size: var(--fs-label);
  font-family: var(--family-bold);
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  margin-top: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.form-check-input:checked {
  background-color: var(--color-blue2) !important;
  border-color: var(--color-blue2) !important;
}

.active-field-report {
  border-left: 10px solid var(--color-blue2);
}

.active-field-report:hover {
  background-color: var(--color-lightgray);
}

.ship-icon {
  height: 3rem;
}

.horizontal-progress {
  margin-bottom: 2rem;
  height: 1.6rem;
  border-radius: 20px;
}

.horizontal-bar p {
  margin-bottom: 0;
  font-size: var(--fs-label);
}

.horizontal-progress > div {
  background-color: var(--color-blue1);
}

.carbon-horizontal-progress {
  height: 1.6rem;
  border-radius: 20px;
}

.carbon-horizontal-bar p {
  margin-bottom: 0;
  font-size: var(--fs-label);
}

.carbon-horizontal-progress > div {
  background-color: var(--color-blue1);
}
.main-container-chart {
  background-color: var(--color-white);
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
}
.main-container-chart-child{
  row-gap: 3rem;
  display: flex;
  flex-direction: column;
}
/* ---New--Preset  */
.newpreset {
  background-color: var(--color-white);
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
}

.header_preset {
  color: var(--color-gray4);
  font-family: var(--family-bold);
  font-size: var(--fs-label);
  margin-left: 1rem;
  margin-top: 0.2rem;
  width: -webkit-fill-available;
  text-overflow: ellipsis;
  overflow: hidden;
}

.form-design {
  border: none;
  border-bottom: 1px solid var(--color-gray5);
  width: 30%;
}

/* ---Deadline */
.deadline-analytics {
  background-color: var(--color-white);
  padding: 1rem 1rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
}

.head-general {
  background-color: var(--color-white);
  width: 20%;
  border-radius: 10px 10px 0 0;
  border-bottom: 10px solid var(--color-blue2);
  margin: 1rem 0;
}

.head-general p {
  margin-bottom: 0;
  text-align: center;
  font-family: "siena-medium";
  font-size: var(--fs-value);
  cursor: pointer;
  color: var(--color-blue2);
}

.general-bottom {
  margin-bottom: -14px;
  width: 100%;
}

.head-deadline {
  margin: 1rem 0;
}

.head-deadline .col-6 {
  padding: 0;
}

.head-mid-deadline {
  background-color: var(--color-white);
  padding: 24px 21px 24px 21px;
  border-radius: 10px;
  font-size: var(--fs-value);
  color: var(--color-gray4);
  font-family: var(--family-bold);
}

.style-head-mid {
  margin-right: 1rem;
}

.information-purpe {
  padding-bottom: 36px;
  display: flex;
}

.btn-information-purpe {
  border: 2px solid var(--color-purpe0);
  color: var(--color-purpe0);
  font-size: var(--fs-one);
  padding: 8px;
  border-radius: 5px;
  background-color: transparent;
  width: 42%;
}

.text-size-purpe {
  font-size: var(--fs-title);
  margin-bottom: 0;
  color: var(--color-purpe0);
}

.text-small-purpe {
  color: var(--color-gray4);
  margin-bottom: 0;
  font-size: var(--fs-title);
}

.information-green {
  padding-bottom: 36px;
  display: flex;
}

.btn-information-green {
  border: 2px solid var(--color-green0);
  color: var(--color-green0);
  font-size: var(--fs-one);
  padding: 8px;
  border-radius: 5px;
  background-color: transparent;
  width: 42%;
}

.text-size-green {
  font-size: var(--fs-title);
  margin-bottom: 0;
  color: var(--color-green0);
}

.text-small-green {
  color: var(--color-gray4);
  margin-bottom: 0;
}

.line-image {
  margin-bottom: -13px;
}

.deadline-filter {
  background-color: var(--color-white);
  border-radius: 0.5rem 0.5rem 0 0;
  border-bottom: 10px solid var(--color-blue2);
  max-width: 30%;
}

.deadline-filter p {
  margin: 0;
  width: 100%;
  text-align: center;
  font-family: var(--family-medium);
  font-size: var(--fs-value);
  cursor: pointer;
}

.deadline-filter .text-secdeadline {
  font-size: 1.25rem;
  color: var(--color-gray4);
  cursor: pointer;
  margin: 0;
  transition: 0.4s;
  text-transform: uppercase;
}

.edit-user-dead {
  padding: 0rem 1rem 1.5rem 0;
  color: var(--color-blue2);
  font-size: var(--fs-one);
  font-family: "siena-bold";
}

.edit-user-dead p {
  margin: 0;
}

.dead-button {
  padding: 0.3rem 1rem;
  color: var(--color-blue1);
  border: 1px solid var(--color-blue1);
  background-color: transparent;
  border-radius: 50px;
  font-size: var(--fs-subtitle);
}

/* --Deadline_Air-- &  --Deadline_Sea-- */
.air-deadline {
  background: var(--color-white);
  padding: 1rem 1.5rem;
  border-radius: 5px;
  margin-top: 1rem;
}

.form-deadline label {
  padding-bottom: 0.5rem;
}

.form-deadline label span {
  font-size: 1.25rem;
  color: var(--color-gray4);
}

.input-air {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  justify-content: space-between;
  align-items: center;
}

.button_save {
  margin-top: 1rem;
}

.button_save button {
  display: block;
  padding: 0.5rem 1.2rem;
  color: var(--color-white);
  border: none;
  background-color: var(--color-blue1);
  border-radius: 4px;
}

/* ------------ */
.demurrage {
  border-spacing: 0 1rem;
  border-collapse: separate;
}

.Demurrage-section {
  background-color: var(--color-white);
  padding: 1rem 1.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
  justify-content: space-between;
}

.edit-button {
  font-size: var(--fs-label);
  border: none;
  color: var(--color-blue1);
  background: none;
}

.style-calender {
  border: none;
  border-bottom: 1px solid var(--color-white1);
  width: 45%;
  font-family: "siena-medium";
  font-size: var(--fs-label);
  color: var(--color-gray2);
  margin-right: 4rem;
}

.demurrage tr {
  background-color: var(--color-white);
}

.demurrage tr:hover {
  background-color: var(--color-lightgray);
}

.demurrage th,
.demurrage td {
  padding: 1rem 0.5rem;
  font-family: "siena-medium";
  font-size: var(--fs-label);
  color: var(--color-gray2);
}

.theadingbelow {
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}

.theading {
  border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.cardDemorage {
  display: none;
}

/* card css */
.card-demu-top {
  background: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;
}

.card-demu {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.card-demu > div {
  width: 15rem;
  padding-bottom: 1rem;
}

.card-demu p {
  margin: 0;
  font-family: "siena-medium";
  font-size: var(--fs-label);
  color: var(--color-gray2);
}

.card-demu p:first-child {
  font-weight: 700;
  color: var(--color-gray2);
}

.card-demu p:nth-child(2) {
  font-weight: 100;
  color: var(--color-gray2);
}

.clickShowMore {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.centerButtonLoadmore > div:last-child {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skeleton-type > span.MuiSkeleton-root {
  transform: none;
  -webkit-transform: none;
}
.date-picker > div {
  min-width: 11rem !important;
}
.date-picker .MuiOutlinedInput-notchedOutline {
  border: none;
  border-bottom: 1px solid var(--color-white1) !important;
  border-radius: 0px;
}
.date-picker .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 2px !important;
}
.date-picker .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  margin-right: -15px;
}
.date-picker .css-1laqsz7-MuiInputAdornment-root {
  margin-left: 0px;
}
.calender-section {
  column-gap: 1rem;
}
.select-section .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--color-blue2);
  font-weight: 700;
  font-size: 1.1rem;
  top: -16px;
}
.select-section  label+.css-1a1fmpi-MuiInputBase-root-MuiInput-root{
  margin-top:0px
}
/* Media Query */

@media only screen and (min-width: 480px) and (max-width: 992px) {
  .style-head-mid {
    margin-right: 0rem;
  }
}
@media screen and (max-width: 925px) {
  .cardDemorage {
    display: block;
  }

  .tableDemorage {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .report {
    margin: 0;
  }

  /* -------Overview---------- */
  .head-section {
    order: 2;
  }

  .last-sec {
    order: 1;
    margin-top: 1rem;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .heading {
    display: none;
  }

  .card-overview {
    padding: 0.5rem;
    margin: 0;
    width: 32%;
  }

  .num-sec {
    font-size: var(--fs-title);
  }

  /* -----Transport---- */
  .head-trans {
    display: none;
  }

  .text-overview {
    padding: 0;
  }

  .calender-section {
    margin: 0;
  }

  .calender {
    margin: 0;
  }

  .transport-mid {
    flex-direction: row;
    padding-top: 1rem;
    width: 100%;
    justify-content: space-between;
  }
  .transport-mid-car {
    flex-direction: row;
    padding-top: 1rem;
    width: 100%;
    justify-content: space-between;
  }

  .props-type {
    margin-top: 0.1rem;
  }

  /* ----datexport------ */
  .dataexport {
    display: none;
  }

  .export-section {
    padding: 1rem 1rem;
  }

  .header-data {
    justify-content: end !important;
  }
}

@media screen and (max-width: 479px) {
  .head-general {
    width: 100%;
  }

  .general-bottom {
    margin-bottom: -10px;
    height: 12px;
  }

  .head-deadline .col-6 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .deadline-filter {
    max-width: 100%;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .head-general {
    width: 100%;
  }

  .general-bottom {
    margin-bottom: -10px;
    height: 12px;
  }

  .head-deadline .col-6 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .deadline-filter {
    max-width: 100%;
  }
}
@media only screen and (min-width: 751px) and (max-width: 991px) {
  .report-sec-first {
    padding-inline: 0;
  }
  .report-sec-second {
    margin-top: 2rem;
  }
  .calender-section {
    margin-right: 1.5rem;
    padding-right: 6%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .head-general {
    width: 100%;
  }

  .general-bottom {
    margin-bottom: -10px;
    height: 12px;
  }

  .head-deadline .col-6 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .deadline-filter {
    max-width: 100%;
  }
  .transport-mid {
    width: 100%;
    justify-content: space-between;
  }
  .transport-mid-car {
    /* width: 100%; */
    justify-content: end;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .transport-mid {
    width: 100%;
    justify-content: space-between;
  }
  .transport-mid-car {
    /* width: 100%; */
    justify-content: end;
  }
}
@media (min-width: 1400px) {
  .Transport-section {
    width: 65%;
  }
  .Demurrage-section {
    width: 70%;
  }
  .deadline-analytics {
    width: 70%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1520px) {
  .calender-section {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1950px) {
  .btn-reset {
    font-size: 1rem;
    margin-right: 0;
  }
  .dead-div {
    display: flex;
    align-items: center;
  }
  .calender {
    margin-right: 1.5rem;
  }
  .transport-mid {
    justify-content: space-between;
    width: 100%;
  }
  .transport-mid-car {
    justify-content: end;
  }
}
.transport-imgs {
  width: 56px;
  margin-left: -3px;
  margin-top: -15px;
  margin-bottom: -9px;
}
