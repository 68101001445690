.milestone-section {
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.milestone-div p {
  margin-bottom: 0;
}

.milestone-div-child {
  width: 29%;
  flex-grow: 1;
}

.hour {
  color: var(--color-gray4);
  font-size: 0.885rem;
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;
  align-items: center;
  padding-left: 3rem;
  font-family: "siena-light" !important;
}

.Marquee-text {
  position: fixed;
  bottom: 0;
  height: 2rem;
  color: rgb(241, 10, 10);
  width: 100%;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: var(--color-gray4);
  z-index: 1;
}

.milestone-line {
  width: 0.15rem;
  height: 70px;
  position: relative;
  left: 29px;
  border: 1px solid var(--color-gray0);
}

.status-name {
  font-size: var(--fs-subtitle);
  color: var(--color-gray4);
  font-family: "siena-light" !important;
}

.status-year {
  font-size: var(--fs-small-md);
  color: var(--color-gray3);
  font-family: var(--family-bold);
}

.status-info {
  font-size: var(--fs-small);
  color: var(--color-gray4);
}
