.day-sections {
  max-height: 66vh;
  /* overflow-y: hidden; */
  overflow-y: scroll;
}

.testdiv {
  display: block;
}

@media (max-width: 758px) {
  .testdiv {
    display: none;
  }
}

.testdiv2 {
  display: none;
}

@media (max-width: 758px) {
  .testdiv2 {
    display: block;
  }
}

@media (max-width: 758px) {
  .container-list-note {
    display: none !important;
  }
}

/* .day-sections:hover {} */
#days-div::-webkit-scrollbar {
  visibility: hidden;
}

#days-div::-webkit-scrollbar-thumb {
  visibility: hidden;
}

#days-div:hover::-webkit-scrollbar {
  visibility: visible;
}

#days-div:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/* #days-inside::-webkit-scrollbar:hover {
  visibility: visible;
}

#days-inside::-webkit-scrollbar-thumb:hover {
  visibility: visible;
} */

.notification-division {
  /* max-width: 1640px; */
  width: calc(100% - 40px);
  margin: 0 auto;
  padding-top: 50px;
}

.notification-head {
  margin-bottom: 32px;
}

.notification-head p {
  margin: 0;
  font-size: 1.7rem;
  color: var(--color-blue2);

  font-family: "siena-bold";
}

.notification-filter {
  max-width: 380px;
  background-color: white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-bottom: 10px solid var(--color-blue2);
}

.notification-filter .filters {
  z-index: 1;
  padding: 0 0.5rem;
}

.notification-filter .filters .text-filter {
  font-family: "siena-regular";
  font-size: 1.5rem;
  color: var(--color-gray4);
  cursor: pointer;
  margin: 0;
  transition: 0.4s;
  padding: 0.3rem;
}

.circle-3days {
  display: flex;
  justify-content: left;
}

.circle-7days {
  display: flex;
  justify-content: right;
}

.circle-5days {
  display: flex;
  justify-content: center;
}

.circle-days img {
  margin-bottom: -10px;
}

.active-color {
  color: var(--color-blue2) !important;
}

/* --------- */
.container-list-notification {
  height: calc(100% - 22rem);
  overflow-y: auto;
  padding: 0;
}

.container-list-note {
  background-color: var(--color-white);
  display: flex;
  border-radius: 5px;
  padding: 0.5rem 0rem;
  margin: 8px 0px;
  align-items: center;
}

.list-img {
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
}

.list-img img {
  height: 2rem;
  width: 5rem;
}

.visible-img {
  visibility: hidden;
  padding: 0 0 0 3rem;
}

.list-img > div {
  border-right: 1px dotted var(--color-gray2);
  padding: 0 1rem 0 0;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-notification {
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-notification {
  background-color: var(--color-white);
  display: flex;
  border-radius: 5px;
  padding: 0.5rem 0rem;
  margin: 3px auto;
}

.list-notification:hover {
  background-color: var(--color-lightgray);
}

.information-table-note {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description-data {
  display: flex;
  justify-content: space-between;
}

.description-data-bottom {
  height: 100%;
  align-items: center;
  display: flex;
}

.description-data p {
  margin-bottom: 0;
  width: 20%;
  font-family: "siena-light";
  font-size: 1.2rem;
  color: var(--color-gray4);
}

.description-data-bottom div {
  width: 20%;
  padding: 0.5rem;
}

.description-data-bottom div p:first-child {
  margin-bottom: 0;
  font-family: "siena-medium";
  font-size: 1.35rem;
  color: var(--color-gray2);
  padding: 0px 5px 6px 0px;
}

.description-data-bottom div p:nth-child(2) {
  font-family: "siena-semibold";
  font-size: var(--fs-value);
  color: var(--color-blue2);
  margin-bottom: 0;
  font-size: 1.5rem;
  padding: 0px 5px 6px 0px;
}

/* ----------- */
/* -----Three days----- */
.threedays-div {
  background-color: var(--color-white);

  display: flex;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.threedays-div:hover {
  background-color: var(--color-lightgray);
}

.threedays-table {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.threedays-table-head {
  display: flex;

  justify-content: space-between;
}

.threedays-table-head p:first-child {
  width: 5.5rem;
}

.threedays-table-head p {
  /* width: 20%; */
  padding: 0.5rem;
  margin: 0;
  font-size: var(--fs-small-md);
  color: var(--color-gray4);
}

.threedays-list-dashboard {
  height: calc(100% - 22rem);
  overflow-y: auto;
  padding-right: 0.2rem;
}

.threedays-card-img {
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
}

.threedays-card-img > div {
  border-right: 1px dotted grey;
  padding: 0 1rem 0 0;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.threedays-card-img div img {
  height: 4rem;
  width: 4rem;
}

.threedays-info-table {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.threedays-table-bottom {
  height: 100%;
  align-items: center;
  display: flex;
}

.threedays-table-bottom div p {
  margin: 0;
}

/* -----Five days----- */
.fivedays-div {
  background-color: var(--color-white);

  display: flex;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.fivedays-div:hover {
  background-color: var(--color-lightgray);
}

.fivedays-table {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.fivedays-table-head {
  display: flex;

  justify-content: space-between;
}

.fivedays-table-head p:first-child {
  width: 5.5rem;
}

.fivedays-table-head p {
  width: 20%;
  padding: 0.5rem;
  margin: 0;
  font-size: var(--fs-small-md);
  color: var(--color-gray4);
}

.fivedays-list-dashboard {
  height: calc(100% - 22rem);
  overflow-y: auto;
  padding-right: 0.2rem;
}

.fivedays-card-img {
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
}

.fivedays-card-img > div {
  border-right: 1px dotted grey;
  padding: 0 1rem 0 0;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fivedays-card-img div img {
  height: 4rem;
  width: 4rem;
}

.fivedays-info-table {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.fivedays-table-bottom {
  height: 100%;
  align-items: center;
  display: flex;
}

.fivedays-table-bottom div {
  width: 20%;
  padding: 0.5rem;
}

.fivedays-table-bottom div p {
  margin: 0;
}

/* -----Seven days----- */
.sevendays-div {
  background-color: var(--color-white);

  display: flex;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.sevendays-div:hover {
  background-color: var(--color-lightgray);
}

.sevendays-table {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.sevendays-table-head {
  display: flex;

  justify-content: space-between;
}

.sevendays-table-head p:first-child {
  width: 5.5rem;
}

.sevendays-table-head p {
  width: 20%;
  padding: 0.5rem;
  margin: 0;
  font-size: var(--fs-small-md);
  color: var(--color-gray4);
}

.sevendays-list-dashboard {
  height: calc(100% - 22rem);
  overflow-y: auto;
  padding-right: 0.2rem;
}

.sevendays-card-img {
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;
}

.sevendays-card-img > div {
  border-right: 1px dotted grey;
  padding: 0 1rem 0 0;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sevendays-card-img div img {
  height: 4rem;
  width: 4rem;
}

.sevendays-info-table {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.sevendays-table-bottom {
  height: 100%;
  align-items: center;
  display: flex;
}

.sevendays-table-bottom div {
  width: 20%;
  padding: 0.5rem;
}

.sevendays-table-bottom div p {
  margin: 0;
}

@media only screen and (min-width: 1651px) and (max-width: 1989px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
    /* flex-grow: 1;
    flex-basis: auto;
    margin: .25em 0;
    padding: 0 1em;
    text-align: center;
    border-left: 1px solid #ccc;
    background-color: #fff; */
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1650px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1585px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1500px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1450px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1390px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1340px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 1070px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 925px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 840px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 758px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 451px) and (max-width: 690px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 450px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

@media (max-width: 390px) {
  .notification-filter .filters .text-filter {
    font-size: 1.5rem;
  }

  .description-data p {
    font-size: 1.2rem;
  }
}

/* NEW CSS */
.list-last-updates .list-items {
  background-color: var(--color-white);
  border-radius: 5px;
  margin: 3px auto;
}

.list-last-updates .list-items .container-list {
  padding: 10px 14px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 75px 1.8fr 3.3fr 1.5fr 1.7fr 1.4fr;
  grid-template-columns: 75px 1.5fr 3fr 1.5fr 1fr 1.1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-last-updates .list-items .container-list img {
  width: 55px;
  height: auto;
}

.list-last-updates .list-items .container-list .from {
  font-family: "siena-medium";
  font-size: 1.22rem;
  color: var(--color-gray2);
  padding: 0px 5px 6px 0px;
  margin: 0 !important;
}

.list-last-updates .list-items .container-list .to {
  font-family: "siena-semibold";
  font-size: 1.25rem;
  color: var(--color-blue2);
}

.list-last-updates .list-items .container-list .btn-dropdown-list-items {
  display: none;
}

.list-last-updates .list-items .container-list .destination {
  border-left: 1px solid var(--color-gray5);
  padding-left: 10px;
}

.list-last-updates .list-items .container-list .ship {
  /* padding-left: 10px; */
  padding-left: 10px;
}

.list-last-updates .list-items .container-list .po-number {
  padding-left: 7px;
}

.container-list p {
  margin: 0;
}

.list-last-updates .list-items:hover {
  background-color: var(--color-gray6);
}

.position-grid-title-po-number,
.position-grid-title-ship,
.position-grid-title-eta,
.position-grid-title-etd,
.position-grid-title-destination {
  display: none;
}

.inactive {
  display: none;
}

@media only screen and (min-width: 762px) and (max-width: 925px) {
  .list-last-updates .list-items .container-list .po-number {
    padding-left: 30px;
  }

  .list-last-updates .list-items .container-list .eta {
    padding-left: 15px;
  }

  .list-last-updates .list-items .container-list .etd {
    padding-left: 47px;
  }
}

@media (max-width: 925px) {
  .small-card .wapper-card .text-card {
    margin-left: 10px;
  }

  .small-card .wapper-card .text-card .title-card {
    font-size: 1.538rem;
  }

  .small-card .wapper-card .text-card p {
    font-size: 2.5rem;
  }

  .small-card-img,
  .large-card-img,
  .medium-card-img,
  .list-card-img {
    width: 60px;
  }

  .medium-card-two .container-table .itens-table .itens p {
    font-size: 1rem;
  }

  .medium-card .wrapper-card .text-card p {
    font-size: 1rem;
  }

  .list-last-updates .list-items .container-list {
    -ms-grid-columns: 75px 1.8fr 3.3fr 1.5fr 1.7fr 1.6fr;
    grid-template-columns: 75px 1.8fr 3.3fr 1.5fr 1.7fr 1.6fr;
  }

  .list-last-updates .list-items .container-list .from {
    font-size: 1rem;
  }

  .list-last-updates .list-items .container-list .to {
    font-size: 1.1rem;
  }
}

@media (max-width: 758px) {
  .list-last-updates .list-items {
    margin: 8px auto;
  }

  .list-last-updates .list-items .container-list {
    padding: 16px 14px 10px 16px;
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    -ms-grid-columns: 30px 40px 73px 30px 100px;
    grid-template-columns: 30px 40px 73px 30px 100px;
    -ms-grid-rows: 14px 50px 36px 30px 17px 14px 32px 16px;
    /* grid-template-rows: 14px 50px 36px 30px 17px 14px 32px 16px; */
    grid-template-rows: 14px 50px 36px 30px;
    gap: 1px;
    /* overflow: hidden;
      height: 123px; */
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .list-last-updates .list-items .container-list.is-open {
    height: 241px;
  }

  .list-last-updates .list-items .container-list .to {
    font-size: 1.25rem;
  }

  .list-last-updates .list-items .container-list .btn-dropdown-list-items {
    display: block;
    -ms-grid-column: 7;
    grid-column: 7;
    -ms-grid-row: 1;
    -ms-grid-row-span: 4;
    grid-row: 1/5;
    border: 0;
    background-color: transparent;
    text-align: end;
  }

  .list-last-updates
    .list-items
    .container-list
    .btn-dropdown-list-items
    .img-arrow-dropdown {
    width: 25px;
    height: auto;
  }

  .list-last-updates .list-items .container-list .list-last-updates-img {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
  }

  .list-last-updates .list-items .container-list .title-table {
    display: block;
    font-family: "siena-light";
    font-size: 0.875rem;
    color: var(--color-gray4);
  }

  .list-last-updates
    .list-items
    .container-list
    .position-grid-title-destination {
    -ms-grid-column: 3;
    grid-column: 3;
    -ms-grid-row: 1;
    grid-row: 1;
  }

  .list-last-updates .list-items .container-list .destination {
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
    grid-column: 3/6;
    -ms-grid-row: 2;
    grid-row: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-left: 0;
    padding: 0px;
  }

  .list-last-updates .list-items .container-list .destination .to {
    margin-left: 10px;
  }

  .list-last-updates .list-items .container-list .position-grid-title-etd {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .etd {
    -ms-grid-column: 2;
    -ms-grid-column-span: 4;
    grid-column: 2/6;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .position-grid-title-eta {
    -ms-grid-column: 4;
    grid-column: 4;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .eta {
    -ms-grid-column: 5;
    grid-column: 5;
    -ms-grid-row: 3;
    grid-row: 3;
  }

  .list-last-updates .list-items .container-list .line-list {
    width: 100%;
    height: 1px;
    background-color: var(--color-gray5);
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    grid-column: 1/8;
    -ms-grid-row: 5;
    grid-row: 5;
  }

  .list-last-updates .list-items .container-list .position-grid-title-ship {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 6;
    grid-row: 6;
  }

  .list-last-updates .list-items .container-list .ship {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    grid-column: 1/6;
    -ms-grid-row: 7;
    grid-row: 7;
  }

  .list-last-updates
    .list-items
    .container-list
    .position-grid-title-po-number {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1/5;
    -ms-grid-row: 8;
    grid-row: 8;
  }

  .list-last-updates .list-items .container-list .po-number {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    grid-column: 1/5;
    -ms-grid-row: 9;
    grid-row: 9;
  }

  .list-last-updates .list-items .container-list img {
    width: 44px;
  }

  .list-last-updates .list-items .container-list .from {
    padding: 0px;
  }
}

@media (max-width: 390px) {
  .list-last-updates .list-items .container-list {
    -webkit-box-align: normal;
    -ms-flex-align: normal;
    align-items: normal;
    -ms-grid-columns: 30px 40px 60px 30px 100px;
    grid-template-columns: 30px 40px 60px 30px 100px;
  }
}
